import { combineReducers } from 'redux';
import essentials, { epics as essentialsEpics } from './essentials';

export const epics = [
  ...essentialsEpics,
];

export default combineReducers({
  essentials,
});
