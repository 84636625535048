import * as R from 'ramda';
import { httpGet, httpPut, httpPost } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString, getIdsObject } from 'utilsModule';

const retrievePatientBills = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`billings/list${queriesString}`),
    response => response.then(adapter.billing.retrievePatientBills.be2fe),
  )(queries);

const updatePatientBillToPaid = async ({ content, params: { ids } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ invoiceId }) => `billings/${invoiceId}`,
  )(ids);

  return R.pipe(
    adapter.billing.updatePatientBillToPaid.fe2be,
    httpPut(url),
    response => response.then(adapter.billing.updatePatientBillToPaid.be2fe),
  )(content);
};

const updatePatientBillToRefunded = async ({ content }) => R.pipe(
    adapter.billing.updatePatientBillToRefunded.fe2be,
    httpPost(`billings/refund`),
    response => response.then(adapter.billing.updatePatientBillToRefunded.be2fe),
  )(content);

const retrievePatientInvoiceByUuid = async ({ params: { ids, queries } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ uuid }) => `billings/${uuid}`,
  )(ids);

  return R.pipe(
    getQueriesString,
    queriesString => httpGet(`${url}${queriesString}`),
    response => response.then(adapter.billing.retrievePatientInvoiceByUuid.be2fe),
  )(queries);
};

const retrieveOrgInvoiceByUuid = async ({ params: { ids, queries } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ uuid }) => `billings/organization/${uuid}`,
  )(ids);

  return R.pipe(
    getQueriesString,
    queriesString => httpGet(`${url}${queriesString}`),
    response => response.then(adapter.billing.retrievePatientInvoiceByUuid.be2fe),
  )(queries);
};

const DM = {
  retrievePatientBills,
  updatePatientBillToPaid,
  updatePatientBillToRefunded,
  retrievePatientInvoiceByUuid,
  retrieveOrgInvoiceByUuid,
};

const ajax = async ({ method, input }) => ({
  [method]: await DM[method](input),
});

export default { ...DM, ajax };
