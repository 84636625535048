import * as R from 'ramda';

const getProviderIndividualPatientReportsList = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

const getProviderAllPatientReportsList = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

const reportArchive = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const reportUnarchive = {
  fe2be: R.identity,
  be2fe: R.identity,
};

export default {
  getProviderIndividualPatientReportsList,
  getProviderAllPatientReportsList,
  reportArchive,
  reportUnarchive,
};
