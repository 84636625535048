import * as R from 'ramda';

const getAdtVisitTypeOptions = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

const getAdtVisitOccurrenceOptions = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

const saveAdtHistory = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

const getAdtHistory = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

export default {
  getAdtVisitTypeOptions,
  getAdtVisitOccurrenceOptions,
  saveAdtHistory,
  getAdtHistory,
};