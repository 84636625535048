import * as R from 'ramda';

const retrieveWarehouseList = {
  fe2be: R.identity,
  be2fe: ({ value }) => (
    { list: value }
  ),
};

const retrieveWarehouseStockList = {
  fe2be: R.identity,
  be2fe: ({ value }) => (
    { list: value }
  ),
};

const retrieveAvailableDevices = {
  fe2be: R.identity,
  be2fe: ({ value: { devices: list = [] } = {} }) => ({
    list,
  }),
};

const retrieveAvailableConsumables = {
  fe2be: R.identity,
  be2fe: ({ value: { consumables: list = [] } = {}, totalRecords: totalCount }) => ({
    list: R.map(({
      lotNumber: lotNo,
      quantity: stock,
      expiredDate: expiryDate,
      sku: { name, description = '', type, model, imageUri: photo } = {},
      chargeItemCode: { currency, priceBeforeTax, priceAfterTax: price } = {},
      ...rest
    }) => ({
      ...rest,
      name,
      // BE: Need to return { imageUri, gothumb }
      photo,
      label: name,
      description,
      lotNo,
      stock,
      expiryDate,
      price,
    }))(list),
    options: {
      pagination: {
        totalCount,
      },
    },
  }),
};

export default {
  retrieveWarehouseList,
  retrieveWarehouseStockList,
  retrieveAvailableDevices,
  retrieveAvailableConsumables,
};
