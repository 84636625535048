/* eslint-disable */
const provider = {
    fe2be: ({ info }) => {
        return {
            user: {
                identification: { 
                    value: info.idNumber, 
                    type: info.idType.value 
                },
                name: { 
                    family: info.firstName, 
                    given: info.lastName 
                },
                // dob:
                // gender:
                // nationality:
                PRN: {
                    value: info.prn,
                    type: 'PRN'
                },
                type: info.role.value.toUpperCase(),
                address: { 
                    line : info.address,
                    city: info.city,
                    district: "",
                    state: "",
                    postalCode: info.postalCode,
                    country: info.countryCode
                },
                // department:
                // speciality:
                mobile: { 
                    value: info.mobile, 
                    countryCode: '65' 
                },
                email: { 
                    value: info.email 
                },
                workPhone: {
                    value: info.office, 
                    countryCode: '65' 
                }
            }
        }
    },
    be2fe: null,
  };
  
  export default {
    provider,
  };

