import network from './network';
import essentials from './essentials';
import policies from './policies';
import countries from './countries';
import programs from './programs';
import inventory from './inventory';

export default {
  network,
  essentials,
  policies,
  countries,
  programs,
  inventory,
};
