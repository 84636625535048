export default {
  vitals: {
    list: [
      {
        id: 0,
        value: 'BP',
        label: 'Blood Pressure',
        thresholds: [
          {
            id: 1,
            name: 'sys',
            label: 'Systolic',
            unit: 'mmHg',
            upperBound: 200,
            lowerBound: 0,
            step: 1,
          },
          {
            id: 2,
            name: 'dia',
            label: 'Diastolic',
            unit: 'mmHg',
            upperBound: 200,
            lowerBound: 0,
            step: 1,
          },
        ],
      },
    ],
  },
};
