import { httpGet } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString, findBy } from 'utilsModule';
import * as R from 'ramda';
import mock from 'dataModule/mock';

const retrieveOne = async ({ params: { queries } }) => R.pipe(
  getQueriesString,
  // queriesString => httpGet(`policies${queriesString}`),
  // BUG: Provide long time enough for getPolicy to be loaded
  queriesString => new Promise(resolve => setTimeout(() => resolve({ value: findBy('type', R.split('=', queriesString)[1], R.path(['policies', 'policies'], mock)) }), 1000)),
  response => response.then(adapter.policies.retrieveOne.be2fe),
)(queries);

const DM = {
  retrieveOne,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
