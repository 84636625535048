import * as R from 'ramda';

const getAdtBaseSurveysList = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

const createAdtBaseSurvey = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

const updateAdtBaseSurvey = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

const getAdtBaseSurvey = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

export default {
  getAdtBaseSurveysList,
  getAdtBaseSurvey,
  createAdtBaseSurvey,
  updateAdtBaseSurvey,
};