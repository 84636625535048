import { Typography } from '@material-ui/core';

const styles = {
  title: {
    marginBottom: 10,
  },
  paragraph: {
    marginBottom: 20,
  },
  center: {
    textAlign: 'center',
  },
};

export default {
  policies: [
    {
      type: 'privacy',
      title: 'Privacy Policy',
      content: (
        <React.Fragment>
          <Typography variant="h5" style={{ ...styles.paragraph }}>
            PERSONAL DATA PROTECTION POLICY
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            HealthBeats® (Singapore) Labs Pte. Ltd. ("we", “us” or "our") recognise the importance of
            the right of the individual ("you" or "your") to protect their personal data. This
            document is our Personal Data Protection Policy (“Privacy Policy”) and explains how we
            collect, use and/or disclose your personal data in compliance with the Personal Data
            Protection Act, 2012 (“Act”).
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            This Privacy Policy applies only to data we collect at and through online interfaces
            (for example, websites, mobile apps and web apps) owned and controlled by us, including
            [HealthBeats®] (collectively the “Site”). Please note that your use of the Site,
            including our collection, use and/or disclosure of your personal data, is also subject
            to our Terms of Use. BY USING THE SITE, YOU ARE ACCEPTING AND AGREEING TO THE PRACTICES
            SET OUT IN THIS PRIVACY POLICY AND ALL OUR TERMS OF USE.
          </Typography>
          <Typography style={{ marginBottom: 50 }}>
            For the purposes of this Privacy Policy, a reference to 'including' or 'for example'
            means including or for example without limitation.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            1. Your Information
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            In this Privacy Policy, personal data have the meaning defined in the Act. In general
            terms, personal data is any data, whether true or not, that (a) by itself can be used to
            personally identify you; or (b) when combined with other data or information that we
            have or are likely to have access to, can be used to personally identify you.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            Minimum Age. You must be at least 18 years old to use the Site (or the age of majority
            in your jurisdiction, if it is older) or create an account with us. By using the Site,
            you represent and warrant that you are at least 18 years old. If you are under 18 years
            old: (i) you may use the Site only with the involvement and agreement of a parent or
            guardian (“Guardian”); (ii) in addition to your personal data, we may collect also the
            Guardian’s name and contact information for verification purposes; and (iii) a Guardian
            must also have read and agreed to the terms of this Privacy Policy (including the Terms
            of Use) and will bear all responsibility of protecting your personal data. If a Guardian
            refuses to consent or accept the terms of this Privacy Policy, or we are unable to
            verify a Guardian’s consent or acceptance of the terms of this Privacy Policy within a
            reasonable time, we have the absolute right to delete all your personal data from the
            Site, including the termination of your Site account and deletion of all information
            contained therein. We will not be responsible or liable for any loss or damage arising
            from such deletion of data or information (including any costs or expenses incurred to
            activate/reactivate the account).
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            2. Information We Collect
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            Depending on the nature of your interaction with us, the types of personal data that we
            collect when you provide to us include: (a) name; (b) NRIC; (c) address; (d) telephone
            number; (e) email address; (f) profession, occupation or job title; (g) photograph, for
            example, for your account profile; (h) health, medical and clinical information that you
            provide to us through the Site, including our mobile apps, web apps and devices that
            integrate with the Site. This information includes your age, date of birth, gender,
            height, weight, race or ethnicity, medical history (including hereditary illnesses or
            diseases, allergies, past and/or present medical treatments sought), living lifestyles
            (including alcohol consumption, smoking habits, sleep patterns, exercise regimes),
            activity and fitness data (including perfusion index, pulse, oxygen saturation, blood
            pressure, heart rate, aerobic steps/strokes taken, blood glucose levels, body fat
            percentage, BMI, calories burnt, metabolism rate); (i) details of products and/or
            services that you have purchased from us or which you have enquired about, together with
            any additional information necessary to process your purchase order and deliver those
            products and services (including payment data such as credit card number), or to respond
            to your enquiries; (j) any additional information relating to you that you provide to us
            directly through the Sites or indirectly through use of the Sites or through our
            representatives; (k) information you provide to us through our customer support centre,
            customer surveys or visits by our representatives from time to time; and (l) any
            information that is provided to us by, or that we have collected on behalf of, customers
            that have outsourced a business process function to us including healthcare providers,
            institutions, organisations, specialists, clinics and doctors.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            We may also collect information that is not personal data because it does not identify
            you or anyone else. For example, we may collect anonymous answers to surveys or
            aggregated information about how users use the Site.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            3. How We Collect Personal Data
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            We collect your personal data directly from you unless it is unreasonable or
            impracticable to do so. We may collect personal data in a number of ways, including: (a)
            through your access and use of the Sites (for example, when you create a Site account
            with us or when data from your health or fitness monitoring devices is transmitted or
            stored on the Site); (b) during conversations between you or your organisation and our
            representatives; and (c) when you submit to us a document containing personal data (for
            example, emails, contact forms, subscription forms, proof of identification, etc.)
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            We may also collect personal data from third parties including: (a) from credit
            reporting agencies; (b) from law enforcement agencies and other government entities; (c)
            from agents, dealers and subcontractors that form part of our sales, business and/or
            service network; (d) from our customers that have outsourced a business process function
            to us; and (e) from our service providers that collect information on our behalf
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            4. Cookies And Similar Technologies
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            A “cookie” is a small summary file containing a unique ID number which is sent to your
            internet enabled device, such as your computer. A web beacon is an electronic image
            which (whether alone or in conjunction with a cookie) is used to compile information
            about your website usage and your interaction with email. We use the term "cookies" to
            cover cookies, web beacons and similar technologies generally used in the market.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            When you access the Site, we may use cookies to help provide you with a more enjoyable,
            relevant, faster and safer experience with us and the Site. Cookies may store
            information to help us recognise your internet enabled device (for example, the internet
            protocol (IP) address of your device) and may collect information (for example, number
            of times a page is visited). In some cases, the information used by cookies may be
            considered personal data if you are reasonably identifiable based on information readily
            available to us.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            A cookie will enable us to greet you each time you visit the Site without bothering you
            with a request to register, and keep track of your interest in particular features,
            products or services on the Site, which we may use to tailor the relevance of news we
            send you. We also use cookies to measure traffic patterns, to determine which areas of
            the Site have been visited and to measure transaction patterns in the aggregate. We use
            this to research our users’ habits so that we can improve our online products and
            services. We may log the information for analytical purposes, for example, to analyse
            trends, administer the Site, track user movements and gather broad demographic
            information.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            If you do not wish to receive cookies, you can set your browser so that your internet
            enabled device does not accept them. However, if these cookies are rejected, this may
            interfere or impair your use of some features of the Site or services.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            5. Consequences If We Cannot Collect Personal Data
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            If you do not provide us with the personal data described above, some or all of the
            following may happen: (a) we may not be able to provide the requested products or
            services to you, either to the same standard or at all; (b) we may not be able to
            provide you with information about products and services that you may want, including
            information about discounts, sales or special promotions; or (c) we may be unable to
            tailor the content of the Site to your preferences and your experience of the Site may
            be impacted and not be as enjoyable or useful.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            6. Purposes For Which We Collect, Use And Disclose Personal Data
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            We collect personal data about you so that we can perform our business activities and
            functions and to provide best possible quality of customer service. These data are held
            in either electronic and/or hardcopy form.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            We collect, use and disclose your personal data for the following purposes: (a) to
            provide products and services to you and to send communications requested by you; (b) to
            answer enquiries and provide information or advice about existing and new products or
            services; (c) to provide you with access to protected areas of the Site; (d) to assess
            and improve the performance, operation and relevance of the Site; (e) to perform
            business processing functions including providing personal data to our related
            corporations, contractors, service providers or other third parties; (f) to service your
            account with us, including but not limited to processing and responding to any complaint
            made by you, investigating problems, resolving disputes and enforcing agreements with
            us; (g) for marketing (including direct marketing), administration, planning, product or
            service improvement and development, quality control and research purposes of us and our
            related corporations, contractors or service providers; (h) to update our records and
            keep your contact details up to date; and (i) if we determine that it is reasonably
            necessary to: (1) comply with any law, rule, regulation, binding determination, decision
            or direction of a regulator or in co–operation with any governmental authority of any
            country; (2) protect any person from death or injury, and includes any response to an
            emergency that threatens the life, health or safety of an individual; or (3) protect our
            rights, property or safety and those of our users or the public as required or permitted
            by law.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            Your personal data will not be shared or disclosed other than as described in this
            Privacy Policy and will be destroyed or de–identified when no longer needed.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            <b>Purchase Orders and Information.</b> To process your purchase orders (including apps
            subscriptions and device rentals), we may require your name, address, phone number,
            email address and credit card information. Please note that credit card transactions are
            processed by our PCI DSS-certified third-party service provider who will collect and use
            your data strictly for payment purposes only.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            7. Disclosure Of Personal Data By Us
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            We may disclose your personal data to our related corporations and to other third-party
            organisations. Except as permitted under the Act, we will only disclose your personal
            data for one of the purposes set out in this Privacy Policy.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            These third-party organisations include: (a) your nominated or authorised healthcare
            provider, institution, organisation, specialist, clinic and doctor; (b) any organisation
            involved in our sales and support network, for example: our authorised sales agents,
            dealers, service providers and subcontractors (for example, delivery services
            providers); (c) any organisation involved in our business processing functions, for
            example: payment processors, credit reporting bodies, debt collection providers; (d) any
            organisation involved in the operation, hosting and management of our information
            technology infrastructure (including our website), for example: web hosting providers,
            IT systems administrators, electronic network administrators; (e) professional advisors,
            for example, accountants, solicitors, business advisors and consultants; (f)
            organisations that perform advertising or marketing related services on our behalf, for
            example, advertising agencies and our authorised agents and dealers; (g) any supplier or
            other third parties with whom we have commercial relationships for business related
            purposes; (h) any organisation if we determine that it is reasonably necessary to: (1)
            comply with any law, rule, regulation, binding determination, decision or direction of a
            regulator or in co–operation with any governmental authority of any country; (2) protect
            any person from death or injury, and includes any response to an emergency that
            threatens the life, health or safety of an individual; or (3) protect our rights,
            property or safety and those of our users or the public as required or permitted by law;
            and (i) in addition to the above examples, any other third-party organisations expressly
            authorised by you.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            We may combine or share any personal data that we collect from you with personal data
            collected by any of our related corporations or by any organisations within our
            authorised sales and support network.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            <b>Transfer of Personal Data in the Event of Change of Control or Sale of HealthBeats®.</b>{' '}
            If the ownership of our business changes, or we otherwise dispose or transfer assets
            relating to our business or the Site to another party (whether by way of sale, merger,
            acquisition, amalgamation, insolvency proceeding or otherwise), you hereby expressly
            agree that we may sell, disclose and/or transfer your personal data to such party.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            Unless otherwise stated in this Privacy Policy or expressly stated at the time of
            collection of your personal data, we will never sell your personal data to any third
            party.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            8. Sharing of Personal Data By You
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            Your personal data (including account details such as user names, PIN codes, passwords
            and security authentications) are private and confidential to you (hereinafter referred
            to as the “Confidential Data”). If you choose to disclose or share any of such
            Confidential Information with a third party (including your healthcare provider,
            specialist, doctor, Guardian, spouse and relative): (i) you do so solely at your own
            risk; and (ii) you expressly acknowledge and agree that we will not be responsible or
            liable in any way whatsoever for: (1) any loss of confidentiality due to disclosure or
            sharing of such Confidential Data by you; or (2) the use and/or processing of such
            Confidential Data by the third party, including any and all losses, damages, liabilities
            and harm arising therefrom, including any act or omission of medical prevention,
            intervention, diagnosis, advice, treatment or care.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            9. Consent
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            From time to time we may seek your consent to a specific proposed collection, use and/or
            disclosure of your personal data. If we decide to bundle multiple requests for your
            consent, you may withhold your consent to any or all such requests.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            If you have consented to a specific purpose for our use and disclosure of your personal
            data, then we may rely on your consent until you withdraw your consent. You may withdraw
            your consent by contacting our representative to whom you originally gave your consent
            or by contacting us at the Contact Us page of the Site.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            10. Direct Marketing Materials
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            We may collect, hold, use and/or disclose your personal data to send you direct
            marketing communications and information which offers, advertises or promotes our
            products and services and which we consider may be of interest to you. These
            communications may be sent in various forms, including voice calls, mail, SMS, fax and
            email.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            If you do not wish to receive marketing communications, you may opt–out of receiving
            these communications from us at any time by using the opt–out feature/facilities
            specified in our direct marketing communications or by contacting us at the Contact Us
            page of the Site. We will then ensure that your details are removed from the relevant
            marketing contact list you have elected to opt–out of.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            11. Access To, Correction/Deletion Of, Your Personal Data
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            When providing any personal data to us, you should take care to only provide us with
            accurate, complete and up-to-date data.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            To the extent the Site allows you to update certain information on your own (including
            correction and/or deletion of your personal data in your Site account), you can always
            choose to do so personally. When you update such information, we usually keep a copy of
            the prior version for our records.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            Otherwise or alternatively, if you wish to apply for a copy of the personal data we hold
            about you; or request for your personal data to be updated or corrected or deleted by
            us, please contact us at the Contact Us page of the Site, and we will take all
            reasonable steps to comply with such requests, as soon as reasonably possible.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            To enable us to review and respond to your requests in a timely manner, please include
            the following details (as a minimum) in your requests: (a) your full legal name and
            telephone contact number; (b) a description of the personal data you are requesting to
            access, correct and/or delete; (c) a date range of when you believe the personal data
            was supplied to us; (d) any details of how the personal data was supplied to us
            originally (for example, when you completed an online subscription form); and (e) where
            correction/deletion is required, details of the correction/deletion requested. We may
            contact you for additional information if required (for example, to clarify your
            request, to confirm your identity etc).
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            <b>Fees.</b> We will not charge you when you make a request for access, correction
            and/or deletion. However, we may charge you for our reasonably incurred costs in
            complying with your request, including: (a) our staff costs for searching, locating and
            retrieving the requested personal data and deciding which personal data to provide; (b)
            costs of producing a copy of the personal data requested (for example,
            photocopying/printing costs); and (c) expenses we have incurred in giving access or
            delivering the personal data (for example, postage costs).
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            To the extent practicable, we will advise you in advance prior to charging you in these
            circumstances and give you an indication of the likely amount.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            12. Personal Data Outside Singapore
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            The Site is operated and managed on servers located and operated within Singapore. By
            using and accessing the Site, residents and citizens of countries and jurisdictions
            outside of Singapore agree and consent to the transfer to and processing of personal
            data on servers located in Singapore, and that the protection of such data may be
            different than required under the laws of your residence or location.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            Your personal data may be disclosed to or accessed by our related corporations and
            third-party organisations (for example, IT or cloud storage service providers) located
            outside of Singapore for some of the purposes specified in this Privacy Policy.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            We may disclose your personal data in any of the following circumstances: (a) to an
            overseas recipient if we have taken reasonable steps (for example, entered into a
            contract) to ensure that the recipient entity does not breach our privacy obligations
            relating to your personal data; (b) to an overseas recipient that is subject to a
            standard of personal data protection or equivalent law or scheme that is comparable to
            the protection afforded under the Act (for example, privacy or data protection law,
            industry privacy scheme or code or binding corporate rules) and you have the ability to
            enforce the law or binding scheme; or (c) to an overseas recipient if we have received
            your express and informed consent before doing so.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            13. Internet Security And Third-Party Websites
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            We will take reasonable steps to protect your personal data from unauthorised access,
            improper use or disclosure, unauthorised modification, unlawful destruction or
            accidental loss.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            However, as the Site are linked to the internet, and the internet is inherently
            insecure, we cannot provide any guarantee, warranty or assurance regarding, nor be held
            liable or responsible for any liability arising out of or in connection with any breach
            of, the security of transmission of information communicated online. In particular, we
            cannot guarantee that information transmitted or communicated will not be intercepted
            while being transmitted over the internet or that such information may not be accessed,
            disclosed, altered, or destroyed by breach of any of our administrative, physical or
            technical safeguards. It is your sole responsibility to protect the security of your
            login and password information. Please note that emails and other communications you
            send through the Site are not be encrypted, we therefore strongly advise you not to
            communicate or disclose any confidential information through these means.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            The Site may contain links to other websites operated by third parties that are not
            owned or controlled by us. These third-party websites are governed by their own separate
            data privacy policies, and we make no representation or warranty in relation to the data
            privacy practices of such third-party websites and will not be responsible for the data
            privacy policies or the content of such third-party websites. Third-party websites are
            responsible for informing you about their own privacy practices.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            14. Personal Data Retention Period
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            Your personal data will be retained for as long as they are required for one or more of
            the purposes for which they were collected, or for legal or business purposes (including
            on-going legal action, accounting and billing administration, and archival).
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            You can close your account with us by going to the Site and [unsubscribing/unregistering
            your account]. If you close your account, we will no longer use your data or share it.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            15. How To Contact Us
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            If you have any question or comments regarding this Privacy Policy or otherwise need to
            contact us, please contact us at the Contact Us page of the Site.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            If your concern relates to a believe that we have failed to comply with a provision of
            the Act or this Privacy Policy that affects your personal data, please provide us the
            following details (as a minimum) to enable us to review and respond to your complaint in
            a timely manner: (a) your full legal name and telephone contact number; (b) a
            description of the incident (including any relevant dates) so that we can review and/or
            investigate the complaint; and (c) a description of how you believe we have breached our
            obligations under this Privacy Policy. We may contact you for additional information if
            required (for example, to clarify your request, to confirm your identity etc).
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            16. Changes To Our Privacy Policy
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            We may change this Privacy Policy at any time and from time to time, so please review it
            frequently. Any updated version of this Privacy Policy will be effective from the date
            of posting on this page.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            17. Applicable Law
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            This Privacy Policy and any dispute of any kind between you and us shall be governed by
            and construed in accordance with the laws of Singapore, and each party irrevocably and
            unconditionally submits to the exclusive jurisdiction of the Courts of Singapore.
          </Typography>
        </React.Fragment>
      ),
      updated: '01-01-2018',
    },
    {
      type: 'eula',
      title: 'Terms of Use',
      content: (
        <React.Fragment>
          <Typography variant="h5" style={{ ...styles.paragraph }}>
            END-USER LICENCE AGREEMENT FOR HealthBeats®
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            THE SOFTWARE AND RELATED SERVICES ARE PROVIDED SUBJECT TO THE TERMS AND CONDITIONS SET
            OUT IN THIS END-USER LICENCE AGREEMENT. PLEASE READ THE FOLLOWING INFORMATION CAREFULLY
            BEFORE DOWNLOADING AND USING THE SOFTWARE.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            This end-user licence agreement (“EULA”) is a legal agreement between you (“End-user”,
            “you” or “your”) and HealthBeats® (Singapore) Labs Pte. Ltd. (UEN. 201626625G), whose
            registered address is 120 Robinson Road #14-01, Singapore 068913 (“HealthBeats”, “us”, “we”
            or “our”) regarding the use of HealthBeats® mobile application software (“Software”)
            that is downloaded, installed and used in Singapore.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            We license the use of the Software to an End-user on the basis of this EULA, HealthBeats’s
            Privacy Policy (as defined in Clause 7.1 below) and subject to any rules or policies
            applied by any appstore provider or operator (“Third Party Appstore”) from whose site
            the End-user downloads the Software (“Third Party Appstore Rules”).
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            By clicking on the “Agree” button below, you expressly acknowledge and agree that you
            have read and understood this EULA (including the Privacy Policy) and agree to be bound
            by the terms set out in this EULA (including the Privacy Policy).
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            If you do not agree to the terms of this EULA, we will not license the Software to you
            and you must immediately exit the Software by clicking the “Cancel” button below. When
            you click on the “Cancel” button, you will stop the downloading process of the Software
            and not be able to use the Software and Services.
          </Typography>
          <Typography style={{ marginBottom: 50 }}>
            For purposes of this EULA, a reference to 'including' or 'for example' means including,
            or for example, without limitation.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            AGREED TERMS:
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            1. ACKNOWLEDGEMENT AND ACCEPTANCE OF TERMS AND CONDITIONS
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            1.1 The terms of this EULA apply to the Software and the services, features, tools,
            data, software and content accessible through the Software (“Services”), including any
            updates or supplements to the Software or any Service, unless they come with separate
            terms, in which case those terms will apply and take precedence over any term that is
            inconsistent with this EULA. If any open-source software is included in the Software or
            any Service, the terms of an open-source licence may override some of the terms of this
            EULA.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            1.2 Updates of the Software incorporating “patches” and correction of errors may be
            issued either directly by us or through Third Party Appstore. Depending on the update,
            you may not be able to use the Software or Services until you have downloaded the update
            and accepted any accompanying new terms. It is your responsibility to download the
            update promptly to ensure your continued use of the Software or Services, and we will
            not be responsible or liable in any way for any delay or loss or corruption in any data
            or information arising from your transitioning to a newer or different version of the
            Software.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            1.3 Minimum Age. You must be at least 18 years old (or the age of majority in your
            jurisdiction, if it is older) in order to create an account with us, and to use the
            Software and Services. By using the Software, you represent and warrant that you are at
            least 18 years old and have the right and legal authority to enter into this EULA on
            your own behalf. If you are under 18 years old: (i) you may use the Software only with
            the involvement and agreement of a parent or guardian (“Guardian”); and (iii) a Guardian
            must also have read and agreed to the terms of this EULA and will bear all
            responsibility under this EULA. If we reasonably believe that you are accessing and/or
            using the Software as a minor, we have the absolute right to stop you from using the
            Software and suspend or terminate your account immediately. We will not be responsible
            or liable for any loss or damage arising from such deletion of account, data or
            information (including any costs or expenses incurred to activate/reactivate the
            account).
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            2. CHANGES TO TERMS AND CONDITIONS
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            2.1 We reserve the right to make changes to this EULA at any time. Any updated version
            of this EULA will be effective from the date of posting of the updated EULA onscreen.
            Please check the terms of this EULA (including any other document referred to herein)
            when you use the Software and the Services, to ensure that you are aware of any changes.
            This EULA will indicate the date they were last updated. Your continued use of the
            Software and the Services, including use after the posting of any changes to these
            terms, will be deemed acceptance by you of the then-current EULA (including any other
            document referred to herein).
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            3. GRANT AND SCOPE OF LICENCE
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            3.1 In consideration of you agreeing to abide by the terms of this EULA, we grant you a
            non-exclusive, non-transferable, non-assignable, non-sublicensable limited license to
            download, install and use the Software and the Services on any smartphones, tablets or
            handheld devices that you use to access the Software and the Services (“Devices”)
            strictly for personal, non-commercial use only, and subject to: (a) this EULA and the
            Privacy Policy and the Third Party Appstore Rules, both of which are incorporated into
            this EULA by reference and as may be amended from time to time; and (b) you remaining
            current in the payment of any indicated licence fees (including any renewal fees) for
            the Software and Services. In the event of any inconsistency between the terms set out
            in this EULA and the Third Party Appstore Rules, the terms of this EULA shall prevail.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            3.2 We do not sell the Software to you. We remain the owners of the Software at all
            times.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            4. LICENCE RESTRICTIONS
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            4.1 Except as expressly set out in this EULA or as permitted by Singapore law, you
            agree:
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (a) not to copy the Software except where such copying is incidental to normal use of
            the Software, or where it is necessary for the purpose of back-up or operational
            security;
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (b) not to sell, resell, licence, sub-licence, transfer, assign, distribute or otherwise
            encumber or commercially exploit, or make the Software available to any third party
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (c) not to duplicate, frame, mirror, republish, display, transmit or distribute all or
            any portion of the Software in any form or media or by any means;
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (d) not to tamper, make alterations to, or modifications of, the whole or any part of
            the Software, or permit the Software or any part of it to be merged or combined with, or
            become incorporated in, any other programmes;
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (e) not to disassemble, decompile, reverse-engineer, reverse compile or create
            derivative works based on the whole or any part of the Software or attempt to do any
            such thing, whether or not such act was carried out to create a software or product that
            is substantially similar to and/or competing with the Software or otherwise;
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (f) to keep all copies of the Software secure and to maintain accurate and up-to-date
            records of the number and locations of all copies of the Software;
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (g) to include our copyright notice on all entire and partial copies you make of the
            Software on any medium;
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (h) not to provide or otherwise make available the Software in whole or in part
            (including object and source code), in any form to any person without prior written
            consent from us; and
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (i) to comply with all technology control or export laws and regulations that apply to
            the technology used or supported by the Software or any Service (“Technology”), together
            “Licence Restrictions”).
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            5. ACCEPTABLE USE RESTRICTIONS
          </Typography>
          <Typography style={{ ...styles.paragraph }}>5.1 You must:</Typography>
          <Typography style={{ ...styles.paragraph }}>
            (a) not use the Software or any Service in any unlawful manner, for any unlawful
            purpose, or in any manner inconsistent with this EULA, or act fraudulently or
            maliciously, for example, attempting to gain unauthorised access to, or hacking into or
            inserting malicious code, including viruses, or harmful data, into the Software, any
            Service or any operating system;
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (b) not infringe our intellectual property rights or those of any third party in
            relation to your use of the Software or any Service, including the submission of any
            material (to the extent that such use is not licensed by this EULA);
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (c) not use the Software or any Service to store or transmit any material that is
            defamatory, offensive or otherwise objectionable, or in a way that could damage,
            disable, overburden, impair or compromise our systems or security or interfere with
            other users; and
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (d) not collect or harvest any information or data from any Service or our systems or
            attempt to decipher any transmissions to or from the servers running any Service.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            You have no right to, and must not, access the Software in source-code form.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            If we reasonably suspect that you have breached or may breach any of the acceptable use
            restrictions in this Clause 5, or any of the other terms in this EULA, we reserve the
            right to stop you from using the Software and suspend or terminate your account
            immediately.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            6. INTELLECTUAL PROPERTY RIGHTS
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            6.1 You acknowledge and agree that: (a) all titles to, and intellectual property rights
            in, the Software and the Technology anywhere in the world belong to us or our licensors;
            (b) all rights in the Software are licensed (not sold) to you strictly for personal,
            non-commercial use only; and (c) you have no rights in, or to, the Software or the
            Technology other than the rights to use each of them in accordance with the terms of
            this EULA.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            6.2 You may not frame or utilise framing techniques to use, surround or endorse any of
            HealthBeats’s names, trademarks, service marks, logos, or other proprietary information
            (including images, text, page layout, or form) of HealthBeats and/or its affiliates without
            our express written consent.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            6.3 “HealthBeats”, “HealthBeats” and related designs are trademark(s) and service mark(s)
            owned by HealthBeats or its affiliates. Our trademarks and service marks may not be used in
            connection with any product or service that is not from HealthBeats, or in any manner that
            is likely to cause confusion among users or consumers, or in any manner that may
            disparage or discredit HealthBeats or its affiliates.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            7. THIRD-PARTY CONTENT AND YOUR USER CONTENT
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            7.1 The Software or Service may contain links to content, data, information,
            applications, web applications, software, or other materials generated or provided by
            third parties that are not owned or controlled by us (“Third-party Content”). Any
            Third-Party Content or links appearing through our Software or Service does not imply
            and shall not be taken that HealthBeats is affiliated with, sponsors, promotes or endorses
            such Third-party Content or links. You expressly acknowledge and agree that HealthBeats
            shall have no obligation, responsibility or liability to you for any damage or loss
            arising from your access to, use or reliance on any Third-party Content or link.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            7.2 Please note that Third-Party Content and links are governed by their own separate
            terms of use and policies (including data privacy policies). You are solely responsible
            for complying with all such terms, conditions and policies.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            7.3 You agree that HealthBeats shall not be liable or otherwise responsible for any photo,
            image, video or other data, content or information you add, create, upload, submit,
            distribute, post or display on or through the software, including in a web-forum, in a
            chat room, or through other interactive or “social networking” portions of the Software
            (“User Content”) and that such User Content may be immediately displayed on the Software
            and treated as Third Party Content. You hereby grant to HealthBeats and its suppliers a
            non-exclusive, transferable, worldwide, royalty free, sublicensable, right and license
            to store, copy, distribute, transmit, publicly perform, display, create derivative works
            of, and otherwise use the User Content for purposes of providing the Software to third
            parties, including the right to transmit the User Content to other users of the
            Software.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            7.4 The generation and distribution of User Content is at your own risk, and you will
            indemnify, defend, and hold harmless HealthBeats and its affiliates and their customers,
            suppliers, employees, officers, directors, and agents from and against any and all
            claims, losses, liabilities, suits, costs, and other damages arising out of or related
            to your User Content, including claims that: (a) the User Content violates, infringes,
            or misappropriates the patent, trademark, trade secret, copyright, or other proprietary
            rights of any party; (b) the User Content is libellous or defamatory; or (c) the User
            Content caused or attributed to the injury or death to a third party.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            8. HealthBeats’S USE OF YOUR DATA AND COMMUNICATIONS
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            8.1 We only use any personal data we collect through your use of the Software and the
            Services in the ways set out in HealthBeats’s personal data protection policy [insert link
            to privacy policy] (“Privacy Policy”). The terms of our Privacy Policy are incorporated
            into this EULA by reference.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            8.2 For the avoidance of doubt, you agree and acknowledge that we may collect, use,
            process and disclose any personal data we collect through your use of the Software and
            the Services including, information relating to your personal particulars, clinical
            data, medical information, and health, fitness and vital signs data and information, and
            share these information with your healthcare organisations, institutions, providers
            and/or professionals including doctors, nurses and caregivers etc. (collectively
            “Healthcare Provider”).
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            8.3 Except as expressly provided in the Privacy Policy, you acknowledge that any User
            Content, communications, material, information, or data of any kind that you email,
            post, or transmit to or through the Software, including question, chats, comments,
            suggestions and other information (“Communications”) will be treated as non-confidential
            and non-proprietary by HealthBeats. You hereby represent and warrant that you have the right
            to provide to HealthBeats any and all Communications that you submit to HealthBeats through the
            Software.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            8.4 By using the Software or Services, you acknowledge and agree that internet
            transmissions are never completely private or secure. You acknowledge that security
            safeguards, by their nature, are capable of circumvention and HealthBeats does not and
            cannot guarantee, warrant or assure that your information or Communications cannot be
            accessed, intercepted, disclosed, altered, or destroyed by unauthorised persons capable
            of overcoming such safeguards, and we will not be liable or responsible for any
            liability arising out of or in connection with any breach of security of transmission of
            Communications disclosed, shared or communicated online. You acknowledge and agree that:
            (i) viruses, worms, Trojan horses, or other undesirable data or software; or (ii)
            unauthorised users (e.g., hackers) may attempt to obtain access to and damage the
            Software, your information or Communications, and/or HealthBeats’s networks. We shall not be
            responsible for such activities or for your inability to access or use the Software in
            such an instance. You are solely responsible for the security and integrity of your
            information, devices and systems.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            8.5 Your account details such as user names, PIN codes, passwords and/or security
            authentications are private and confidential to you (“Account Details”). You are solely
            responsible for protecting and maintaining the confidentiality of your Account Details
            and will be solely responsible for all activities that occur under your account. You
            expressly acknowledge and agree that we will not be responsible or liable in any way
            whatsoever for any loss or damage incurred arising out of the unauthorised use of your
            account.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            9. WHERE YOUR CONTENT IS STORED
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            9.1 You consent and agree to permit us to store any User Content via cloud computing
            with a third party provider, for the purposes of providing you with continued access to
            the Software and the Services. We will take all steps reasonably necessary to ensure
            that the User Content is treated securely and in accordance with this EULA and the
            applicable laws and regulations. You agree that your submission of any User Content is
            at your sole risk, and we hereby disclaim any and all liability to you for any loss or
            liability relating to such content in any way, to the maximum extent permitted by law.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            9.2 As mentioned earlier, User Content which are uploaded, transmitted, submitted,
            posted or otherwise provided via any Third-party Content, link, site, or any third party
            products or services may be subject to separate terms and conditions as may be imposed
            by such third parties. As such, you hereby expressly acknowledge and agree that: (a)
            such third parties may then retain the right to store or use the User Content on their
            servers or databases; and (b) we shall not be responsible or liable in any way in
            relation to their storage or usage of the User Content.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            10. ACCURACY AND INTEGRITY OF INFORMATION
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            10.1 Although HealthBeats attempts to ensure the integrity and accuracy of the Software and
            the Services, we cannot provide any warranty or guarantee as to the completeness,
            correctness or accuracy of the Software or the Services (including any product
            descriptions or other content therein). It is possible the Software or the Services may
            include typographical errors, inaccuracies or other errors, and that unauthorised
            additions, deletions and alterations could be made to the Software or the Services by
            third parties. In the event that an inaccuracy arises, please inform HealthBeats so that it
            can be corrected. Information contained in the Software, including this EULA, may be
            changed or updated without notice.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            11. TERMINATION
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            11.1 We may terminate this EULA immediately and without any liability whatsoever by
            written notification to you:
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (a) if you commit a breach of this EULA which you fail to remedy (if remediable) within
            [fourteen (14) days] after the service of written notice requiring you to do so; or
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (b) if you breach any of the Licence Restrictions or the Acceptable Use Restrictions; or
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (c) if you fail to pay the applicable license or support fees as and when they are due;
            or
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            (d) if we decide, in our sole discretion, to no longer support the Software or
            discontinue the Software indefinitely.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            11.2 On termination for any reason:
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (a) all rights granted to you under this EULA shall cease;
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (b) you must immediately cease all activities authorised by this EULA, including your
            use of the Software and any Services; and
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (c) you must immediately delete, uninstall and remove the Software from all Devices, and
            destroy all copies of the Software in your possession, custody or control, failing
            which, we may remotely access the Devices and remove the Software from all of them and
            cease providing you with access to the Services.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            12. INDEMNITY
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            12.1 By using the Software and the Services, you agree, to the maximum extent permitted
            by law, to indemnify and hold us and our directors, partners, officers, employees,
            affiliates, agents, contractors and licensors harmless from and against all claims,
            demands, actions, proceedings, losses, liabilities, damages, costs and expenses
            (including attorneys fees and costs) directly or indirectly arising from or relating to
            your (a) access and/or use of the Software and/or the Services; (b) breach of any of the
            terms and conditions contained in this EULA; or (c) violation of any third party right.
            You agree to cooperate as fully as reasonably required in the defence of any claim.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            13. DISCLAIMERS
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            You expressly acknowledge and agree that:
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            13.1 We do not guarantee, represent, or warrant that your use of the Software or
            Services will be uninterrupted, error-free, or free from loss, corruption, attack,
            viruses, interference, hacking or other security intrusion.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            13.2 From time to time, we may remove or disable the Software or the Services for
            indefinite periods of time, or cancel the Software or the Services at any time, without
            notice to you.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            13.3 Your use of and access to the Software or the Services is at your sole risk. You
            acknowledge that the Software has not been developed to meet your individual
            requirements, and that it is therefore your responsibility to ensure that the facilities
            and functions of the Software meet your requirements.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            13.4 The Software and the Services are (except as expressly stated by us) provided on an
            “as is” and “as available” basis for your use, without warranties of any kind, either
            express or implied, including all implied warranties of merchantability, fitness for a
            particular purpose, title, and non-infringement, to the maximum extent permitted by law.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            13.5 You will obtain all relevant and necessary authorisations, consents and permissions
            from the owners of any Devices not owned by you to download and install a copy of the
            Software onto such Devices, and to use and access the Software and the Services on such
            Devices. You accept responsibility in accordance with the terms of this EULA for the use
            of the Software or any Services on or in relation to any Device, whether or not it is
            owned by you.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            13.6 We shall not be liable or responsible for any failure to perform, or delay in
            performance of, any of our obligations under this EULA that is caused by any act or
            event beyond our reasonable control, including failure of any public or private
            telecommunications networks.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            13.7 While the Software may provide a communication channel between you and your
            Healthcare Provider, you agree that: (a) we do not exercise any influence or control
            whatsoever over any Healthcare Provider; and (b) we do not make any representations
            warranties of any kind in respect of any Healthcare Provider or its services in any way.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            14. LIMITATION OF LIABILITY
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            14.1 We supply the Software and the Services to you strictly for personal and
            non-commercial use only. You agree not to use the Software and the Services for any
            commercial, business or resale purposes.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            14.2 Notwithstanding any term in this EULA, you acknowledge and agree that, to the
            maximum extent permissible under law, we and our directors, partners, officers,
            employees, affiliates, agents, contractors or licensors shall have no responsibility,
            obligation or liability to you in any way:
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (a) any loss or damage you may suffer arising from or in connection with your use, or
            inability to use, or the result of your use of the Software or the Services, or for any
            other claim related in any way to the foregoing, including, but not limited to, any
            defect, interruption, delay, unavailability or failure of the Software or Services; any
            inaccuracy, error or omission in any information, data, material or content appearing in
            the Software or Services (whether sent or received by or to you via the Software); or
            any loss or damage of any kind incurred as a result of the use of, or reliance on any
            information, data, material or content posted, transmitted, or otherwise made available
            via the Software or the Services, even if advised of their possibility; or
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (b) for any (i) loss of income or revenue; (ii) loss of business profits or contracts;
            (iii) business interruption; (iv) loss of the use of money or anticipated savings; (v)
            loss of information; (vi) loss of opportunity, goodwill or reputation; (vii) loss of,
            damage to or corruption of data, network, property, device and/or equipment; (viii)
            injury or death; or (ix) any special, indirect, incidental, punitive, exemplary or
            consequential loss or damage of any kind howsoever arising and whether caused by tort
            (including negligence), breach of contract or otherwise, even if foreseeable.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            14.3. <b>HealthBeats DOES NOT PROVIDE MEDICAL ADVICE.</b> The Software (including contents
            of the Software) and Services provided thereunder do not constitute and are not intended
            to be a substitute for: (a) professional medical advice; (b) professional medical care,
            treatment, management or intervention; (c) diagnosis or recommendation of any kind. You
            should always consult and seek the advice of your Healthcare Provider (or any other
            qualified Healthcare Provider) with any query or concern that you may have regarding
            your medical and healthcare needs and conditions. HealthBeats does not recommend or endorse
            any specific test, product, prescription, procedure, opinion or other information that
            may be mentioned or communicated on or through the Software or Services, and you
            expressly acknowledge and agree that we shall not be responsible or liable in any way
            for any claim, demand, action, proceeding, loss, liability, damage, cost or expenses
            howsoever arising from or relating to:
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (a) your reliance on any data or information provided through the Software or Services
            because of something you have read or come across when using the Software or Services.
            Your reliance on such information is solely at your own risk; or
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            (b) any act or omission of a Healthcare Provider.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            15. COMMUNICATION BETWEEN US
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            15.1 If you have any question, issue or dispute in relation to this EULA (including any
            question regarding its existence, validity or termination) or otherwise need to contact
            us, please contact us by mail, email or telephone at HealthBeats® (Singapore) Labs Pte. Ltd.
            (UEN. 201626625G), whose registered address is 120 Robinson Road #14-01, Singapore
            068913, [insert email] or [insert telephone number].
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            We will use reasonable endeavour to review and respond to your queries in a timely
            manner.
          </Typography>
          <Typography variant='h6' style={{ ...styles.title }}>
            16. OTHER IMPORTANT TERMS
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            16.1 <b>Entire Agreement.</b> This EULA (including all documents referred to herein)
            constitute the entire agreement between you and us in relation to your use of the
            Software and the Services, and supersedes any and all prior agreements between you and
            us.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            16.2 <b>Transfer; Assignment.</b> We may assign, novate, delegate or otherwise transfer
            our rights and obligations under this EULA to another organisation, but this will not
            affect your rights or our obligations under this EULA. You may not transfer your rights
            or obligations under this EULA to another person without our prior agreement in writing.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            16.3 <b>No Third Party Rights.</b> Persons not party to this EULA have no rights under
            the Contracts (Rights of Third Parties Act (Cap. 53B) to enforce any term of this EULA.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            16.4 <b>Waiver.</b> If we fail to insist that you perform any of your obligations under
            this EULA, or if we do not enforce our rights against you, or if we delay in doing so,
            that will not mean that (a) we have waived our rights against you or (b) that you do not
            have to comply with those obligations. If we do waive a default by you, we will only do
            so in writing, and that will not mean that we will automatically waive any later default
            by you.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            16.5 <b>Severability.</b> Each of the clauses of this EULA operate separately and
            independently. If any court or competent authority decides that any of them are unlawful
            or unenforceable, the remaining clauses will remain in full force and effect.
          </Typography>
          <Typography style={{ ...styles.paragraph }}>
            16.6 <b>Governing Law and Jurisdiction.</b> This EULA and any dispute of any kind
            between you and us shall be governed by and construed in accordance with the laws of
            Singapore, and each party irrevocably and unconditionally submits to the exclusive
            jurisdiction of the Courts of Singapore.
          </Typography>
        </React.Fragment>
      ),
      updated: '01-01-2018',
    },
  ],
};
