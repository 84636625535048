import * as R from 'ramda';

const getFeedbacks = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const getFeedbacksAverage = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const getFeedbacksResponseStats = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const getFeedback = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const saveFeedbackQuestion = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const updateFeedbackQuestion = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const getFeedbackQuestionOverallData = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const getFeedbackQuestionOrganizations = {
  fe2be: R.identity,
  be2fe: R.identity,
};

export default {
  getFeedbacks,
  getFeedbacksAverage,
  getFeedbacksResponseStats,
  getFeedback,
  saveFeedbackQuestion,
  updateFeedbackQuestion,
  getFeedbackQuestionOverallData,
  getFeedbackQuestionOrganizations,
};
