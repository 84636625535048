import * as R from 'ramda';
import { httpGet } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString, getQueriesObject } from 'utilsModule';
import mock from 'dataModule/mock';
import { attachPaginationQueryInfo } from 'dataModule/dataManager/utils';

const retrieveWarehouseList = async () =>
  R.pipe(
    () => httpGet('warehouse/list'),
    response => response.then(adapter.inventory.retrieveWarehouseList.be2fe),
  )();

const retrieveWarehouseStockList = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`warehouseStock/list${queriesString}`),
    response => response.then(adapter.inventory.retrieveWarehouseStockList.be2fe),
  )(queries);

// TODO: Default value of params & queries for others too
const retrieveAvailableDevices = async ({ params: { queries = [] } = {} }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`device/available${queriesString}`),
    // R.always(Promise.resolve({ value: { devices: mock.inventory.availableDevices } })),
    response => response.then(adapter.inventory.retrieveAvailableDevices.be2fe),
  )(queries);

const retrieveAvailableConsumables = async ({ params: { queries = [] } = {} }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`consumable/warehouses/available${queriesString}`),
    // queriesString => new Promise(resolve => setTimeout(() =>
    //   resolve(mock.inventory.availableConsumables[queriesString]), mock.network.delay)),
    response => response.then(R.pipe(
      adapter.inventory.retrieveAvailableConsumables.be2fe,
      attachPaginationQueryInfo(getQueriesObject(queries)),
    )),
  )(queries);

const DM = {
  retrieveWarehouseList,
  retrieveWarehouseStockList,
  retrieveAvailableDevices,
  retrieveAvailableConsumables,
};

// TODO: Default value of input for others too
const ajax = async ({ method, input = {} }) => ({
  [method]: await DM[method](input),
});

export default { ...DM, ajax };
