import * as R from 'ramda';

const retrieveAvailableDevice = {
  fe2be: R.identity,
  be2fe: ({ value: { devices } }) => (
    {
      list: devices,
    }
  ),
};

const retrieveDeviceRecordsByPatientId = {
  fe2be: R.identity,
  be2fe: ({ value: { deviceRecords, user } }) => (
    {
      list: deviceRecords,
      patientInfo: user,
    }
  ),
};

const createReturnOrReplaceDeviceRecord = {
  fe2be: R.identity,
  be2fe: R.identity,
};

export default {
  retrieveAvailableDevice,
  retrieveDeviceRecordsByPatientId,
  createReturnOrReplaceDeviceRecord,
};
