import * as R from 'ramda';
import { httpGet, httpPost, httpPut } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString } from 'utilsModule';

const getAdtBaseSurveysList = async ({ params: { queries } }) => {
  return R.pipe(
    getQueriesString,
    queriesString => httpGet(`survey/${queriesString}`),
    response => response.then(adapter.adtSurvey.getAdtBaseSurveysList.be2fe),
  )(queries);
};

const getAdtBaseSurvey = async ({ params: { queries } }) => {
  return R.pipe(
    R.prop('baseSurveyId'),
    queriesString => httpGet(`survey/${queriesString}`),
    response => response.then(adapter.adtSurvey.getAdtBaseSurvey.be2fe),
  )(queries);
};

const createAdtBaseSurvey = async ({ content }) => R.pipe(
  adapter.adtSurvey.createAdtBaseSurvey.fe2be,
  httpPost('survey/'),
  response => response.then(adapter.adtSurvey.createAdtBaseSurvey.be2fe),
)(content);

const updateAdtBaseSurvey = async ({ content }) => {
  const url = R.pipe(
    ({ baseSurveyId }) => `survey/${baseSurveyId}`,
  )(content);
  return R.pipe(
    adapter.adtSurvey.updateAdtBaseSurvey.fe2be,
    httpPut(url),
    response => response.then(adapter.adtSurvey.updateAdtBaseSurvey.be2fe),
  )(content)
};


const DM = {
  getAdtBaseSurvey,
  getAdtBaseSurveysList,
  createAdtBaseSurvey,
  updateAdtBaseSurvey,
};

const ajax = async ({ method, input }) => ({
  [method]: await DM[method](input)
});

export default { ...DM, ajax };