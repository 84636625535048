/* eslint-disable */
import api from 'apiModule/rest';
import adapter from 'dataModule/adapter';

const consumableSales = body => {
  const endpoint = `consumableSales/customers/${body.patientId}?paymentMethod=${body.paymentMethod}&warehouseId=${body.warehouseId}`;
  return api.consumableSales.consumableSales(endpoint, adapter.consumableSales.consumableSales.fe2be(body));
};

export default {
  consumableSales,
};
