import * as R from 'ramda';

const retrieveMany = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ list: value }),
};

const retrieveCities = {
  // Apply to input.content only, not input.params
  fe2be: R.identity,
  be2fe: ({ value }) => ({ list: value }),
};

const retrieveIdTypes = {
  // Apply to input.content only, not input.params
  fe2be: R.identity,
  be2fe: ({ data, error }) => (
    error ? do { throw error.message; } : data
  ),
};

const retrieveiHealthCountries = {
  // Apply to input.content only, not input.params
  fe2be: R.identity,
  be2fe: ({ value }) => ({ list: value }),
};

export default {
  retrieveMany,
  retrieveCities,
  retrieveIdTypes,
  retrieveiHealthCountries,
};
