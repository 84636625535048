export default {
  availableDevices: [
    {
      id: 11,
      sku: {
        id: 14,
        type: 'PO',
        model: 'PO3M',
        category: 'SERIALIZED_ITEM',
        name: 'PO3M Device',
        skuLowStockThreshold: {},
        warehouseStock: {},
      },
      address: '5CF821CD06E6',
      barcode: '5CF821CD06E6',
      condition: 'NORMAL',
      isPatientOwned: false,
      canBeReused: true,
    },
    {
      id: 12,
      sku: {
        id: 4,
        type: 'BP',
        model: 'BP5',
        category: 'SERIALIZED_ITEM',
        name: 'BP5 Device',
        skuLowStockThreshold: {},
        warehouseStock: {},
      },
      address: '8CDE5241D3AE',
      barcode: '8CDE5241D3AE',
      condition: 'NORMAL',
      isPatientOwned: false,
      canBeReused: true,
    },
    {
      id: 13,
      sku: {
        id: 10,
        type: 'HS',
        model: 'HS6S',
        category: 'SERIALIZED_ITEM',
        name: 'HS6S Device',
        skuLowStockThreshold: {},
        warehouseStock: {},
      },
      address: '123451CD06E6',
      barcode: '123451CD06E6',
      condition: 'NORMAL',
      isPatientOwned: false,
      canBeReused: true,
    },
    {
      id: 14,
      sku: {
        id: 2,
        type: 'BG',
        model: 'BG5',
        category: 'SERIALIZED_ITEM',
        name: 'BG5 Device',
        skuLowStockThreshold: {},
        warehouseStock: {},
      },
      address: '8CDE52B5CB2E',
      barcode: '8CDE52B5CB2E',
      condition: 'NORMAL',
      isPatientOwned: false,
      canBeReused: true,
    },
    {
      id: 15,
      sku: {
        id: 21,
        type: 'ECG',
        model: 'ECG3',
        category: 'SERIALIZED_ITEM',
        name: 'ECG3 Device',
        skuLowStockThreshold: {},
        warehouseStock: {},
      },
      address: 'F4E45B49BA4F',
      barcode: 'F4E45B49BA4F',
      condition: 'NORMAL',
      isPatientOwned: false,
      canBeReused: true,
    },
    {
      id: 16,
      sku: {
        id: 20,
        type: 'BTM',
        model: 'FDIR-V3',
        category: 'SERIALIZED_ITEM',
        name: 'Thermometer',
        skuLowStockThreshold: {},
        warehouseStock: {},
      },
      address: '5065836C7CF4',
      barcode: '5065836C7CF4',
      condition: 'NORMAL',
      isPatientOwned: false,
      canBeReused: true,
    },
  ],
  availableConsumables: {
    skuIds: [161],
    '?skuIds=161&page_no=1&page_size=3': {
      totalRecords: 9,
      totalPages: 3,
      value: {
        consumables: [
          {
            id: 1,
            lotNumber: '170525003',
            quantity: 15,
            expiredDate: '2019-07-30T16:00:00.000Z',
            sku: {
              id: 161,
              name: 'iHealth disposable ECG electrodes',
              type: 'ECGP',
              model: 'ECGP-1000',
              category: 'LOT_ITEM',
              imageUri: 'https://s3-ap-southeast-1.amazonaws.com/prod-miloassets/consumable/ECG-consumables_750x750.png',
            },
            chargeItemCode: {
              id: 12,
              currency: 'SGD',
              priceBeforeTax: 0,
              priceAfterTax: 0,
              taxPercent: 7,
              taxAmount: 0,
            },
          },
          {
            id: 2,
            lotNumber: '170525002',
            quantity: 5,
            expiredDate: '2018-08-11T16:00:00.000Z',
            sku: {
              id: 161,
              name: 'iHealth disposable ECG electrodes',
              type: 'ECGP',
              model: 'ECGP-1000',
              category: 'LOT_ITEM',
              imageUri: 'https://s3-ap-southeast-1.amazonaws.com/prod-miloassets/consumable/ECG-consumables_750x750.png',
            },
            chargeItemCode: {
              id: 12,
              currency: 'SGD',
              priceBeforeTax: 0,
              priceAfterTax: 0,
              taxPercent: 7,
              taxAmount: 0,
            },
          },
          {
            id: 3,
            lotNumber: '170525001',
            quantity: 5,
            expiredDate: '2019-02-27T16:00:00.000Z',
            sku: {
              id: 161,
              name: 'iHealth disposable ECG electrodes',
              type: 'ECGP',
              model: 'ECGP-1000',
              category: 'LOT_ITEM',
              imageUri: 'https://s3-ap-southeast-1.amazonaws.com/prod-miloassets/consumable/ECG-consumables_750x750.png',
            },
            chargeItemCode: {
              id: 12,
              currency: 'SGD',
              priceBeforeTax: 0,
              priceAfterTax: 0,
              taxPercent: 7,
              taxAmount: 0,
            },
          },
        ],
      },
    },
    '?skuIds=161&page_no=2&page_size=3': {
      totalRecords: 9,
      totalPages: 3,
      value: {
        consumables: [
          {
            id: 4,
            lotNumber: '170525004',
            quantity: 15,
            expiredDate: '2019-07-30T16:00:00.000Z',
            sku: {
              id: 161,
              name: 'iHealth disposable ECG electrodes',
              type: 'ECGP',
              model: 'ECGP-1000',
              category: 'LOT_ITEM',
              imageUri: 'https://s3-ap-southeast-1.amazonaws.com/prod-miloassets/consumable/ECG-consumables_750x750.png',
            },
            chargeItemCode: {
              id: 12,
              currency: 'SGD',
              priceBeforeTax: 0,
              priceAfterTax: 0,
              taxPercent: 7,
              taxAmount: 0,
            },
          },
          {
            id: 5,
            lotNumber: '170525005',
            quantity: 5,
            expiredDate: '2018-08-11T16:00:00.000Z',
            sku: {
              id: 161,
              name: 'iHealth disposable ECG electrodes',
              type: 'ECGP',
              model: 'ECGP-1000',
              category: 'LOT_ITEM',
              imageUri: 'https://s3-ap-southeast-1.amazonaws.com/prod-miloassets/consumable/ECG-consumables_750x750.png',
            },
            chargeItemCode: {
              id: 12,
              currency: 'SGD',
              priceBeforeTax: 0,
              priceAfterTax: 0,
              taxPercent: 7,
              taxAmount: 0,
            },
          },
          {
            id: 6,
            lotNumber: '170525006',
            quantity: 5,
            expiredDate: '2019-02-27T16:00:00.000Z',
            sku: {
              id: 161,
              name: 'iHealth disposable ECG electrodes',
              type: 'ECGP',
              model: 'ECGP-1000',
              category: 'LOT_ITEM',
              imageUri: 'https://s3-ap-southeast-1.amazonaws.com/prod-miloassets/consumable/ECG-consumables_750x750.png',
            },
            chargeItemCode: {
              id: 12,
              currency: 'SGD',
              priceBeforeTax: 0,
              priceAfterTax: 0,
              taxPercent: 7,
              taxAmount: 0,
            },
          },
        ],
      },
    },
    '?skuIds=161&page_no=3&page_size=3': {
      totalRecords: 9,
      totalPages: 3,
      value: {
        consumables: [
          {
            id: 7,
            lotNumber: '170525007',
            quantity: 15,
            expiredDate: '2019-07-30T16:00:00.000Z',
            sku: {
              id: 161,
              name: 'iHealth disposable ECG electrodes',
              type: 'ECGP',
              model: 'ECGP-1000',
              category: 'LOT_ITEM',
              imageUri: 'https://s3-ap-southeast-1.amazonaws.com/prod-miloassets/consumable/ECG-consumables_750x750.png',
            },
            chargeItemCode: {
              id: 12,
              currency: 'SGD',
              priceBeforeTax: 0,
              priceAfterTax: 0,
              taxPercent: 7,
              taxAmount: 0,
            },
          },
          {
            id: 8,
            lotNumber: '170525008',
            quantity: 5,
            expiredDate: '2018-08-11T16:00:00.000Z',
            sku: {
              id: 161,
              name: 'iHealth disposable ECG electrodes',
              type: 'ECGP',
              model: 'ECGP-1000',
              category: 'LOT_ITEM',
              imageUri: 'https://s3-ap-southeast-1.amazonaws.com/prod-miloassets/consumable/ECG-consumables_750x750.png',
            },
            chargeItemCode: {
              id: 12,
              currency: 'SGD',
              priceBeforeTax: 0,
              priceAfterTax: 0,
              taxPercent: 7,
              taxAmount: 0,
            },
          },
          {
            id: 9,
            lotNumber: '170525009',
            quantity: 5,
            expiredDate: '2019-02-27T16:00:00.000Z',
            sku: {
              id: 161,
              name: 'iHealth disposable ECG electrodes',
              type: 'ECGP',
              model: 'ECGP-1000',
              category: 'LOT_ITEM',
              imageUri: 'https://s3-ap-southeast-1.amazonaws.com/prod-miloassets/consumable/ECG-consumables_750x750.png',
            },
            chargeItemCode: {
              id: 12,
              currency: 'SGD',
              priceBeforeTax: 0,
              priceAfterTax: 0,
              taxPercent: 7,
              taxAmount: 0,
            },
          },
        ],
      },
    },
  },
};
