import api from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import * as R from 'ramda';

const admin = {
  fetchAdminProgramList: async pagination => R.pipe(adapter.program.adminProgramList.fe2be, ({ page, pageSize }) => api.program.admin.fetchAdminProgramList(`adminPrograms?page=${page}&pageSize=${pageSize}`), response => response.then(adapter.program.adminProgramList.be2fe))(pagination),
  createAdminProgram: async program => R.pipe(adapter.program.adminProgram.fe2be, R.assoc('status', 'READY'), api.program.admin.createAdminProgram('adminPrograms'))(program),
  saveDraftAdminProgram: async program => R.pipe(adapter.program.adminProgram.fe2be, R.assoc('status', 'DRAFT'), api.program.admin.saveDraftAdminProgram('adminPrograms'))(program),
};

const provider = {
  fetchAvailableProgramList: async ({ patientId }) => R.pipe(() => api.program.provider.fetchAvailableProgramList(`patients/${patientId}/availablePrograms?isBaseEnrolment=true`), response => response.then(adapter.program.availableProgramList.be2fe))(),
  fetchActiveBaseProgramList: async ({ patientId, epId }) => R.pipe(() => api.program.provider.fetchActiveBaseProgramList(`patients/${patientId}/availablePrograms?isBaseEnrolment=false&baseEpId=${epId}`), response => response.then(adapter.program.availableProgramList.be2fe))(),
  fetchEnrolledProgramListNoPagination: async ({ patientId }) => R.pipe(() => api.program.provider.fetchEnrolledProgramListNoPagination(`patients/${patientId}/enrolledPrograms?group=1`), response => response.then(adapter.program.enrolledProgramList.be2fe))(),
  fetchDischargedEnrolledProgramListNoPagination: async ({ patientId }) => R.pipe(() => api.program.provider.fetchDischargedEnrolledProgramListNoPagination(`patients/${patientId}/enrolledProgramsDischarged?group=1`), response => response.then(adapter.program.enrolledProgramList.be2fe))(),
  enrollProgram: async ({ patientId, program }) => R.pipe(adapter.program.enrolledProgram.fe2be, R.assoc('status', 'ENROLLED'), api.program.provider.enrollProgram(`patients/${patientId}/enrolledPrograms`))(program),
  saveDraftEnrolledProgram: async ({ patientId, program }) => R.pipe(adapter.program.enrolledProgram.fe2be, R.assoc('status', 'DRAFT'), api.program.provider.enrollProgram(`patients/${patientId}/enrolledPrograms`))(program),
  getConsentId: async ({ patientId }) => api.program.provider.consentContractNo(`patients/${patientId}/contractNo?type=PPA`),
  getLoanId: async ({ patientId }) => api.program.provider.loanContractNo(`patients/${patientId}/contractNo?type=DAF`),
};

const DM = {
  admin,
  provider,
};

export default DM;
