import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import config from 'dataModule/config/';
import program from 'appModule/program/ducks';
import patient from 'appModule/roles/patient/ducks';
import tasks from 'appModule/tasks/ducks';
import measurements from 'appModule/measurements/ducks';
import note from 'appModule/note/ducks';
import alerts from 'appModule/alerts/ducks';
import chat from 'appModule/chat/ducks';
import inventory from 'appModule/inventory/ducks';
import consumables from 'appModule/consumables/ducks';
import resources from 'dataModule/store/resources';
import message from 'appModule/message/ducks';
import globalSetting from 'appModule/globalSetting/ducks';
import globalNotification from 'appModule/globalNotification/ducks';
import globalSession from 'appModule/globalSession/ducks';
import feedbacks from 'appModule/feedback/ducks';

export default combineReducers({
  /* redux-form */
  form: formReducer,
  /* react-router-redux */
  router: routerReducer,
  /* app */
  config,
  program,
  patient,
  tasks,
  measurements,
  note,
  alerts,
  chat,
  inventory,
  resources,
  consumables,
  message,
  globalSetting,
  globalNotification,
  globalSession,
  feedbacks
});
