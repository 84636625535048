import * as R from 'ramda';
import { httpGet, httpPut } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getIdsObject, getQueriesString } from 'utilsModule';

const retrieveAvailableDevice = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`device/available${queriesString}`),
    response => response.then(adapter.devices.retrieveAvailableDevice.be2fe),
  )(queries);

const retrieveDeviceRecordsByPatientId = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ patientId }) => httpGet(`deviceRecord/patients/${patientId}`),
    response => response.then(adapter.devices.retrieveDeviceRecordsByPatientId.be2fe),
  )(ids);

const createReturnOrReplaceDeviceRecord = async ({ content, params: { ids } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ patientId }) => `patients/${patientId}/replace-or-return`,
  )(ids);

  return R.pipe(
    adapter.devices.createReturnOrReplaceDeviceRecord.fe2be,
    httpPut(url),
    response => response.then(adapter.devices.createReturnOrReplaceDeviceRecord.be2fe),
  )(content);
};

const DM = {
  retrieveAvailableDevice,
  retrieveDeviceRecordsByPatientId,
  createReturnOrReplaceDeviceRecord,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
