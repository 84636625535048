import * as R from 'ramda';

const mock = {
  genders: {
    list: [
      { id: 0, value: 'Male', label: 'Male' },
      { id: 1, value: 'Female', label: 'Female' },
    ],
  },
};

const retrieveOne = null;

const retrieveMany = async () => R.pipe(
  // () => httpGet('genders'),
  // response => response.then(adapter.genders.retrieveMany.be2fe),
  R.always(mock.genders),
)();

const DM = {
  retrieveOne,
  retrieveMany,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
