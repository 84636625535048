import * as R from 'ramda';
import { httpGet, httpPost } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString } from 'utilsModule';

const getAdtVisitTypeOptions = async () => R.pipe(
  () => httpGet('adt/visitTypes'),
  response => response.then(adapter.adt.getAdtVisitTypeOptions.be2fe),
)();

const getAdtVisitOccurrenceOptions = async () => R.pipe(
  () => httpGet('adt/visitOccurrenceTypes'),
  response => response.then(adapter.adt.getAdtVisitOccurrenceOptions.be2fe),
)();

const saveAdtHistory = async ({ content }) => R.pipe(
  adapter.adt.saveAdtHistory.fe2be,
  httpPost('adt/adtHistory'),
  response => response.then(adapter.adt.saveAdtHistory.be2fe),
)(content);

const getAdtHistory = async ({ params: { queries } }) => {
  return R.pipe(
    getQueriesString,
    queriesString => httpGet(`adt/adtHistory${queriesString}`),
    response => response.then(adapter.adt.getAdtHistory.be2fe),
  )(queries);
};

const DM = {
  getAdtVisitTypeOptions,
  getAdtVisitOccurrenceOptions,
  saveAdtHistory,
  getAdtHistory,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
