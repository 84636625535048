/* eslint-disable */
import * as R from 'ramda';

const create = {
  fe2be: ({ name, condition: { value: category }, description, members }) => ({
    name,
    description,
    category,
    participant: R.map(({ primary, profile: { id: profileId } = {} }) => ({ profileId, isPrimary: primary }))(members),
  }),
  be2fe: ({ value }) => {
    return {
      careteam: {
        name: value.name,
        description: value.description,
        condition: value.category,
        members: R.map(
          ({
            profile: {
              speciality,
              role,
              mobile,
              name: { firstName, lastName },
              profile_photo,
              id,
              orgId,
              onboarded,
              activated,
            } = {},
            isPrimary,
            ...rest
          }) => ({
            profile: {
              id,
              role,
              onboard: onboarded,
              isActivated: activated,
              orgId,
              speciality,
            },
            demographic: {
              id,
              mobile,
              identity: {
                avatar: profile_photo,
                firstName,
                lastName,
              },
            },
            primary: isPrimary,
            ...rest,
          }),
        )(value.participant),
      },
    };
  },
};

const retrieveMany = {
  fe2be: R.identity,
  be2fe: ({
    value: {
      teams,
      pagination: { page, pageSize, total },
    },
  }) => ({
    list: R.map(
      ({
        category: condition,
        participant: members,
        updatedBy: {
          demographics: { name: updatedBy, profile_photo },
        },
        updatedAt,
        ...rest
      }) => ({
        condition,
        members: R.map(({ isPrimary: primary, profile = {} } = {}) => ({
          id: R.path(['id'], profile),
          primary,
          salutation: R.path(['name', 'prefix'], profile),
          firstName: R.path(['name', 'firstName'], profile),
          lastName: R.path(['name', 'lastName'], profile),
          avatar: R.path(['profile_photo'], profile),
          mobile: R.path(['mobile'], profile),
          role: R.path(['role'], profile),
          speciality: R.path(['speciality'], profile),
        }))(members),
        modified: { avatar: profile_photo || {}, name: updatedBy || {}, at: updatedAt },
        ...rest,
      }),
    )(teams),
    options: {
      pagination: {
        currentPage: page - 1,
        pageSize,
        totalCount: total,
      },
    },
  }),
};

const retrieveAvailableMembers = {
  fe2be: R.identity,
  be2fe: ({
    value: {
      providers,
      pagination: { page, pageSize, total },
    },
  }) => ({
    list: R.map(
      ({
        profile: { specialty: { name: specialty } = {}, ...otherProfileProps } = {},
        demographic: {
          profile_photo = '',
          name: { firstName = '', lastName = '' } = {},
          ...otherDemographicProps
        } = {},
        ...rest
      }) => ({
        profile: {
          ...otherProfileProps,
          specialty: { value: specialty, label: specialty },
        },
        demographic: {
          ...otherDemographicProps,
          identity: {
            avatar: profile_photo,
            firstName,
            lastName,
          },
        },
        ...rest,
      }),
    )(providers),
    options: {
      pagination: {
        currentPage: page - 1,
        pageSize,
        totalCount: total,
      },
    },
  }),
};

const retrieveOne = {
  fe2be: R.identity,
  be2fe: ({ value }) => {
    return {
      careteam: {
        name: value.name,
        description: value.description,
        condition: value.category,
        members: R.map(
          ({
            profile: {
              speciality,
              role,
              mobile,
              name: { firstName, lastName },
              profile_photo,
              id,
              orgId,
              onboarded,
              activated,
            } = {},
            isPrimary,
            ...rest
          }) => ({
            profile: {
              id,
              role,
              onboard: onboarded,
              isActivated: activated,
              orgId,
              speciality,
            },
            demographic: {
              id,
              mobile,
              identity: {
                avatar: profile_photo,
                firstName,
                lastName,
              },
            },
            primary: isPrimary,
            ...rest,
          }),
        )(value.participant),
      },
    };
  },
};

const update = create;

const retrieveCaretemHistory = {
  fe2be: R.identity,
  be2fe: ({ value: { movements } }) => ({
    list: movements,
  }),
};

const retrieveMemberTree = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({
    memberTree: value,
  }),
};

const replaceMember = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const retrieveCareteamKioskMapping = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({
    careteams: [value.careteamId],
  }),
};

export default {
  create,
  update,
  retrieveMany,
  retrieveAvailableMembers,
  retrieveOne,
  retrieveCaretemHistory,
  retrieveMemberTree,
  replaceMember,
  retrieveCareteamKioskMapping,
};
