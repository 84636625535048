/* eslint-disable */
const consumableSales = {
  fe2be: (body) => ({
    records: body.records
  }),
  be2fe: null
};

export default {
  consumableSales,
};
