export default {
  adminPrograms: [
    {
      id: 26,
      title: 'Test Pricing',
      description: 'Desc',
      category: 'HEART_FAILURE',
      status: 'DRAFT',
      duration: {
        calendar: 'DAY',
        length: 1,
      },
      devices: [],
      vitals: [],
      prices: [
        {
          currency: '',
          priceBeforeTax: '0.00',
          durationUnit: 'MONTHLY',
        },
        {
          currency: '',
          priceBeforeTax: '0.00',
          durationUnit: 'ONCE',
        },
      ],
      created: {
        name: null,
        at: null,
      },
      updated: {
        name: null,
        at: null,
      },
    },
    {
      id: 27,
      title: 'TestPricing1',
      description: 'Desc',
      category: 'AMI',
      status: 'DRAFT',
      duration: {
        calendar: 'DAY',
        length: 1,
      },
      devices: [],
      vitals: [],
      prices: [
        {
          currency: '',
          priceBeforeTax: '0.00',
          durationUnit: 'MONTHLY',
        },
        {
          currency: '',
          priceBeforeTax: '0.00',
          durationUnit: 'ONCE',
        },
      ],
      created: {
        name: null,
        at: null,
      },
      updated: {
        name: null,
        at: null,
      },
    },
    {
      id: 28,
      title: 'Test Status',
      description: 'Desc',
      category: 'HEART_FAILURE',
      status: 'DRAFT',
      duration: {
        calendar: 'DAY',
        length: 1,
      },
      devices: [
        {
          skuId: 4,
          chargeItemCodeId: 42,
        },
      ],
      vitals: [
        {
          id: 39,
          vitalGroupId: 1,
          vitalGroupName: 'BP',
          schedule: {
            taskType: 'NON_BG',
            scheduleType: 'FREQUENCY',
            scheduleDetails: {
              frequency: {
                calendar: 'WEEKLY',
                times: 1,
              },
            },
          },
          notifications: [],
          thresholds: {
            sys: [
              null,
              1,
              2,
              null,
            ],
            dia: [
              null,
              3,
              4,
              null,
            ],
          },
          skuId: 4,
          patientInstructions: 'PtInnnsssSta',
        },
      ],
      prices: [
        {
          currency: 'SGD',
          priceBeforeTax: '0.00',
          durationUnit: 'MONTHLY',
        },
        {
          currency: 'SGD',
          priceBeforeTax: '19.00',
          durationUnit: 'ONCE',
        },
      ],
      created: {
        name: {
          family: 'hospital',
          given: 'doctor',
          prefix: null,
          suffix: null,
        },
        at: '2018-04-12T09:35:33.230Z',
      },
      updated: {
        name: {
          family: 'hospital',
          given: 'doctor',
          prefix: null,
          suffix: null,
        },
        at: '2018-04-12T09:35:33.338Z',
      },
    },
    {
      id: 29,
      title: 'Test Timeslots',
      description: 'Test Timeslots Desc',
      category: 'AMI',
      status: 'DRAFT',
      duration: {
        calendar: 'WEEK',
        length: 7,
      },
      devices: [
        {
          skuId: 4,
          chargeItemCodeId: 44,
        },
      ],
      vitals: [
        {
          id: 40,
          vitalGroupId: 1,
          vitalGroupName: 'BP',
          schedule: {
            taskType: 'NON_BG',
            scheduleType: 'TIMESLOT',
            scheduleDetails: {
              timeslot: [
                {
                  start: '1652',
                  end: '1752',
                },
              ],
            },
          },
          notifications: [],
          thresholds: {
            sys: [
              null,
              1,
              2,
              null,
            ],
            dia: [
              null,
              1,
              2,
              null,
            ],
          },
          skuId: 4,
          patientInstructions: 'Pat Ins BP',
        },
      ],
      prices: [
        {
          currency: 'SGD',
          priceBeforeTax: '0.00',
          durationUnit: 'MONTHLY',
        },
        {
          currency: 'SGD',
          priceBeforeTax: '39.00',
          durationUnit: 'ONCE',
        },
      ],
      created: {
        name: {
          family: 'hospital',
          given: 'doctor',
          prefix: null,
          suffix: null,
        },
        at: '2018-04-18T08:53:08.321Z',
      },
      updated: {
        name: {
          family: 'hospital',
          given: 'doctor',
          prefix: null,
          suffix: null,
        },
        at: '2018-04-18T08:53:08.781Z',
      },
    },
  ],
};
