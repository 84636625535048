import * as R from 'ramda';
import { httpGet, httpPost, httpPut, httpDelete } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getIdsObject, getQueriesString } from 'utilsModule';

const retrievePatient = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ patientId }) => httpGet(`patients/${patientId}`),
    (response) => response.then(adapter.patients.retrievePatient.be2fe),
  )(ids);

const retrieveActiveEnrolledPrograms = async ({ params: { patientId, queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`patients/${patientId}/activeEnrolledPrograms/${queriesString}`),
    (response) => response.then(adapter.patients.retrieveActiveEnrolledPrograms.be2fe),
  )(queries);

const retrievePP = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ patientId, ppId }) => httpGet(`patients/${patientId}/enrolledPrograms/${ppId}`),
    (response) => response.then(adapter.programs.retrievePP.be2fe),
  )(ids);

const createPatientExtensionSuspension = async ({ params: { patientId }, content: { data } }) =>
  R.pipe(
    adapter.patients.createPatientExtensionSuspension.fe2be,
    httpPost(`patients/${patientId}/extensionSuspension/`),
    (response) => response.then(adapter.patients.createPatientExtensionSuspension.be2fe),
  )(data);

const updatePatientExtensionSuspension = async ({ content, params: { patientId, ids } }) => {
  const url = R.pipe(getIdsObject, ({ id }) => `patients/${patientId}/extensionSuspension/${id}`)(ids);

  return R.pipe(adapter.patients.updatePatientExtensionSuspension.fe2be, httpPut(url), (response) => response.then(adapter.patients.updatePatientExtensionSuspension.be2fe))(
    content,
  );
};

const retrievePatientExtensionSuspension = async ({ params: { patientId, queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`patients/${patientId}/extensionSuspension/${queriesString}`),
    (response) => response.then(adapter.patients.retrievePatientExtensionSuspension.be2fe),
  )(queries);

const retrievePatientOneExtensionSuspension = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ patientId, extensionSuspensionId }) =>
      httpGet(`patients/${patientId}/extensionSuspension/${extensionSuspensionId}`),
    (response) => response.then(adapter.patients.retrievePatientOneExtensionSuspension.be2fe),
  )(ids);

const deleteExtensionSuspension = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ patientId, extensionSuspensionId }) =>
      httpDelete(`patients/${patientId}/extensionSuspension/${extensionSuspensionId}`),
    (response) => response.then(adapter.patients.deleteExtensionSuspension.be2fe),
  )(ids);

const DM = {
  retrievePatient,
  retrieveActiveEnrolledPrograms,
  createPatientExtensionSuspension,
  updatePatientExtensionSuspension,
  retrievePatientExtensionSuspension,
  retrievePatientOneExtensionSuspension,
  deleteExtensionSuspension,
  retrievePP,
};

const ajax = async ({ method, input }) => ({
  [method]: await DM[method](input),
});

export default { ...DM, ajax };
