import * as R from 'ramda';
import { httpGet } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString } from 'utilsModule';

const retrieveChangeRequestFormByUuid = async ({ params: { queries } }) => {
  return R.pipe(
    getQueriesString,
    queriesString => httpGet(`contracts${queriesString}`),
    response => response.then(adapter.changeRequest.retrieveChangeRequestFormByUuid.be2fe),
  )(queries);
};

const DM = {
  retrieveChangeRequestFormByUuid,
};

const ajax = async ({ method, input }) => ({
  [method]: await DM[method](input),
});

export default { ...DM, ajax };
