import * as R from 'ramda';
import { httpGet } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString } from 'utilsModule';

const mock = {
  nationalities: {
    list: [
      { id: 0, value: 'sg', label: 'Singaporean' },
      { id: 1, value: 'vn', label: 'Vietnamese' },
      { id: 2, value: 'my', label: 'Malaysian' },
      { id: 3, value: 'th', label: 'Thai' },
    ],
  },
};

const retrieveOne = null;

const retrieveMany = async () => R.pipe(
  // () => httpGet('nationalities'),
  // response => response.then(adapter.nationalities.retrieveMany.be2fe),
  R.always(mock.nationalities),
)();

const DM = {
  retrieveOne,
  retrieveMany,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
