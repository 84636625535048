import * as R from 'ramda';
import { httpGet, httpPost, httpPut } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString, getQueriesObject, getIdsObject } from 'utilsModule';
import { attachPaginationQueryInfo } from 'dataModule/dataManager/utils';
import * as _ from 'lodash';

const getFeedbacks = async ({ params: { queries = [] } = {} }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`feedback/feedbackQuestion${queriesString}`),
    response => response.then(R.pipe(
      adapter.feedbacks.getFeedbacks.be2fe,
      attachPaginationQueryInfo(getQueriesObject(queries)),
    )),
  )(queries);

const getFeedback = async ({ params: { ids } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ feedbackQuestionId }) => `feedback/feedbackQuestion/${feedbackQuestionId}`,
  )(ids);
  const response = await httpGet(url);
  return adapter.feedbacks.getFeedbacks.be2fe(response);
};

const saveFeedbackQuestion = async ({ content }) =>
  R.pipe(
    adapter.feedbacks.saveFeedbackQuestion.fe2be,
    httpPost('feedback/feedbackQuestion'),
    response => response.then(adapter.feedbacks.saveFeedbackQuestion.be2fe),
  )(content);

const updateFeedbackQuestion = async ({ params: { ids }, content }) => {
  const { value: feedbackQuestionId } = _.find(ids, { name: 'feedbackQuestionId' });
  const url = `feedback/feedbackQuestion/${feedbackQuestionId}`;
  const response = await httpPut(url, content);
  return adapter.feedbacks.updateFeedbackQuestion.be2fe(response);
};

const getFeedbacksAverage = async ({ params: { queries = [] } = {} }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`feedback/feedbackQuestion/average${queriesString}`),
    response => response.then(R.pipe(
      adapter.feedbacks.getFeedbacksAverage.be2fe,
      attachPaginationQueryInfo(getQueriesObject(queries)),
    )),
  )(queries);

const getFeedbacksResponseStats = async ({ params: { queries = [] } = {} }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`feedback/feedbackQuestion/response-stats${queriesString}`),
    response => response.then(R.pipe(
      adapter.feedbacks.getFeedbacksResponseStats.be2fe,
      attachPaginationQueryInfo(getQueriesObject(queries)),
    )),
  )(queries);

const getFeedbackQuestionOverallData = async ({ params: { ids } }) => {
  const { value: feedbackQuestionId } = _.find(ids, { name: 'feedbackQuestionId' });
  const url = `feedback/feedbackQuestion/${feedbackQuestionId}/overall-stats`;
  const response = await httpGet(url);
  return adapter.feedbacks.getFeedbackQuestionOverallData.be2fe(response);
};

const getFeedbackQuestionOrganizations = async ({ params: { ids, queries = [] } }) => {
  const { value: feedbackQuestionId } = _.find(ids, { name: 'feedbackQuestionId' });
  const queriesString = getQueriesString(queries);
  const url = `feedback/feedbackQuestion/${feedbackQuestionId}/organizations${queriesString}`;
  const response = await httpGet(url);
  const mappedResponse = adapter.feedbacks.getFeedbackQuestionOrganizations.be2fe(response);
  return attachPaginationQueryInfo(getQueriesObject(queries))(mappedResponse);
};


const DM = {
  getFeedbacks,
  getFeedbacksAverage,
  getFeedbacksResponseStats,
  getFeedback,
  saveFeedbackQuestion,
  updateFeedbackQuestion,
  getFeedbackQuestionOverallData,
  getFeedbackQuestionOrganizations,
};

const ajax = async ({ method, input = {} }) => ({
  [method]: await DM[method](input),
});

export default { ...DM, ajax };
