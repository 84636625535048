import api from 'apiModule/rest';

const defaultBody = {
  timezone: 'Asia/Singapore',
};

// fetch patient today's tasks
// const fetchList = (name = "measurements") => api.measurement.fetchList(name);
// const fetchList = (name = 'tasks') => (api.task.fetchList('tasks', defaultBody));
const fetchList = (params) =>
  api.measurement
    .fetchList('measurements', { ...defaultBody, ...params })
    // .then(evolve({ value: { data: identity } }))
    .then(({ value }) => value)
    .catch((error) => {
      // error handler
      throw new Error(error);
    });

export default {
  fetchList,
};
