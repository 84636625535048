import * as R from 'ramda';
import { httpGet } from 'apiModule/rest';
import { getQueriesString } from 'utilsModule';
import adapter from 'dataModule/adapter';

const retrieveMany = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`sku/${queriesString}`),
    response => response.then(adapter.sku.retrieveMany.be2fe),
  )(queries);

const DM = {
  retrieveMany,
};

const ajax = async ({ method, input }) => ({
  [method]: await DM[method](input),
});

export default { ...DM, ajax };
