import * as R from 'ramda';

const retrieveNextContractNo = {
  fe2be: R.identity,
  be2fe: ({ value }) => (
    { value }
  ),
};

export default {
  retrieveNextContractNo,
};
