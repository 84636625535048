import * as R from 'ramda';

const retrievePatientBills = {
  fe2be: R.identity,
  be2fe: ({ value: { invoices }, totalRecords }) => (
    {
      list: invoices,
      options: {
        pagination: {
          totalCount: totalRecords,
        },
      },
    }
  ),
};

const updatePatientBillToPaid = {
  fe2be: body => ({
    type: body.type,
  }),
  be2fe: R.prop('value'),
};

const updatePatientBillToRefunded = {
  fe2be: body => ({
    refundDate: body.refundDate,
    patientCreditNoteId: body.patientCreditNoteId,
    patientPaymentId: body.patientPaymentId,
    refundPaymentType: body.refundPaymentType,
    refundAmount: body.refundAmount,
    recipientProfileId: body.recipientProfileId,
    outstandingPatientInvoiceIds: body.outstandingPatientInvoiceIds,
    refundAmountBeforeOffset: body.refundAmountBeforeOffset,
  }),
  be2fe: R.prop('value'),
}

const retrievePatientInvoiceByUuid = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const retrieveOrgInvoiceByUuid = {
  fe2be: R.identity,
  be2fe: R.identity,
};

export default {
  retrievePatientBills,
  updatePatientBillToPaid,
  updatePatientBillToRefunded,
  retrievePatientInvoiceByUuid,
  retrieveOrgInvoiceByUuid,
};
