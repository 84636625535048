import * as R from 'ramda';
import { httpGet, httpPost, httpPut, httpDelete } from 'apiModule/rest';
import { getQueriesString, getIdsObject } from 'utilsModule';
import adapter from 'dataModule/adapter';

const create = async ({ content }) =>
  R.pipe(
    adapter.chargeitemcodes.create.fe2be,
    httpPost('chargeItemCode'),
    response => response.then(adapter.chargeitemcodes.create.be2fe),
  )(content);

const edit = async ({ content, params: { ids } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ chargeItemCodeId }) => `chargeItemCode/${chargeItemCodeId}`,
  )(ids);

  return R.pipe(
    adapter.chargeitemcodes.edit.fe2be,
    httpPut(url),
    response => response.then(adapter.chargeitemcodes.edit.be2fe),
  )(content);
};

const deleteOne = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ chargeItemCodeId }) => httpDelete(`chargeItemCode/${chargeItemCodeId}`),
    response => response.then(adapter.chargeitemcodes.deleteOne.be2fe),
  )(ids);

const publish = async ({ content, params: { ids } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ chargeItemCodeId }) => `chargeItemCode/${chargeItemCodeId}/publish`,
  )(ids);

  return R.pipe(
    adapter.chargeitemcodes.publish.fe2be,
    httpPost(url),
    response => response.then(adapter.chargeitemcodes.publish.be2fe),
  )(content);
};

const retrieveOne = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ chargeItemCodeId }) => httpGet(`chargeItemCode/${chargeItemCodeId}`),
    response => response.then(adapter.chargeitemcodes.retrieveOne.be2fe),
  )(ids);

const retrieveMany = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`chargeItemCode/list${queriesString}`),
    response => response.then(adapter.chargeitemcodes.retrieveMany.be2fe),
  )(queries);

const retrieveCovidTier = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`covid/chargeCodes${queriesString}`),
    response => response.then(adapter.chargeitemcodes.retrieveCovidTier.be2fe),
  )(queries);

const retrieveActive = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`chargeItemCode/active${queriesString}`),
    response => response.then(adapter.chargeitemcodes.retrieveActive.be2fe),
  )(queries);

const retrieveActiveIhealth = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`chargeItemCode/active/ihealth${queriesString}`),
    response => response.then(adapter.chargeitemcodes.retrieveActiveIhealth.be2fe),
  )(queries);

const DM = {
  create,
  edit,
  deleteOne,
  publish,
  retrieveOne,
  retrieveMany,
  retrieveActive,
  retrieveActiveIhealth,
  retrieveCovidTier,
};

const ajax = async ({ method, input }) => ({
  [method]: await DM[method](input),
});

export default { ...DM, ajax };
