import { httpGet } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString, getQueriesObject } from 'utilsModule';
import { attachPaginationQueryInfo } from 'dataModule/dataManager/utils';
import * as _ from 'lodash';

const getFeedbackResponseForOrganization = async ({ params: { ids, queries = [] } }) => {
  const { value: orgId } = _.find(ids, { name: 'orgId' });
  const { value: feedbackQuestionId } = _.find(ids, { name: 'feedbackQuestionId' });
  const queriesString = getQueriesString(queries);
  const url = `feedback/feedbackQuestion/${feedbackQuestionId}/organization/${orgId}/feedbackResponse${queriesString}`;
  const response = await httpGet(url);
  const mappedResponse = adapter.feedbackResponse.getFeedbackResponseForOrganization.be2fe(response);
  return attachPaginationQueryInfo(getQueriesObject(queries))(mappedResponse);
};

const DM = {
  getFeedbackResponseForOrganization
};

const ajax = async ({ method, input = {} }) => ({
  [method]: await DM[method](input),
});

export default { ...DM, ajax };
