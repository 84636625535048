import * as R from 'ramda';


const getAggregatedTaskWithObservationCompiled = {
  fe2be: R.identity,
  be2fe: R.identity,
};

export default {
  getAggregatedTaskWithObservationCompiled
};
