import { httpGet } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import mock from 'dataModule/mock';
import * as R from 'ramda';

const retrieveVitals = async () => R.pipe(
  () => httpGet('configs/essentials'),
  response => response.then(adapter.essentials.retrieveVitals.be2fe),
  // R.always(mock.essentials.vitals),
)();

const retrieveSkus = async () => R.pipe(
  () => httpGet('configs/essentials'),
  response => response.then(adapter.essentials.retrieveSkus.be2fe),
)();

const retrieveConditions = async () => R.pipe(
  () => httpGet('configs/essentials'),
  response => response.then(adapter.essentials.retrieveConditions.be2fe),
)();

const retrieveCalendars = async () => R.pipe(
  () => httpGet('configs/essentials'),
  response => response.then(adapter.essentials.retrieveCalendars.be2fe),
)();

const DM = {
  retrieveVitals,
  retrieveSkus,
  retrieveConditions,
  retrieveCalendars,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
