import * as R from 'ramda';

const retrieveMany = {
  fe2be: R.identity,
  be2fe: R.identity,
};

export default {
  retrieveMany,
};
