import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/delay';
import update from 'immutability-helper';
import * as R from 'ramda';
import { memoize } from 'utilsModule';
import DM from 'dataModule';

const createResourceDuck = (resourceType) => {
  /*
  **************************************************
    Action Types
  **************************************************
  */
  const actionTypes = {
    AJAX: Symbol(`${resourceType}/AJAX`),
    AJAX_SUCCESS: Symbol(`${resourceType}/AJAX_SUCCESS`),
    AJAX_FAILURE: Symbol(`${resourceType}/AJAX_FAILURE`),
    RESET: Symbol(`${resourceType}/RESET`),
    CLEAR_CACHE: Symbol(`${resourceType}/CLEAR_CACHE`),
  };

  /*
  **************************************************
    Action Creators
  **************************************************
  */
  const actionCreators = {
    ajax: payload => ({ type: actionTypes.AJAX, payload }),
    ajaxSuccess: payload => ({ type: actionTypes.AJAX_SUCCESS, payload }),
    ajaxFailure: payload => ({ type: actionTypes.AJAX_FAILURE, payload }),
    reset: payload => ({ type: actionTypes.RESET, payload }),
    clearCache: payload => ({ type: actionTypes.CLEAR_CACHE, payload }),
  };

  /*
  **************************************************
    Caching Mechanism
    Scope: per entire resource
    Opt-in: useLast (if last result is available in cache)
    Timeout: 15mins & session-based (manually done via RESET on new session)
  **************************************************
  */
  const CACHE_TIMEOUT = 900000;
  let fetch = memoize(DM[resourceType].ajax, { timeout: CACHE_TIMEOUT });

  /*
  **************************************************
    Epics
  **************************************************
  */
  const epics = [
    action$ => action$.ofType(actionTypes.AJAX)
      .mergeMap(({ payload: { cargo, options: { useLast = false } = {}, onSuccess, onFailure } }) =>
        Observable.fromPromise(fetch(cargo, { memoize: { renew: !useLast } }))
          .map((data) => {
            onSuccess && onSuccess({ cargo, data });
            return actionCreators.ajaxSuccess({ cargo, data });
          })
          .catch((error) => {
            onFailure && onFailure({ cargo, error });
            return Observable.of(actionCreators.ajaxFailure({ cargo, error }));
          })),
  ];

  /*
  **************************************************
    State Getters
  **************************************************
  */
  const getState = R.path(['resources', resourceType]);
  const getMethod = method => (from, root = true) =>
    R.path(R.concat(root ? ['resources'] : [], [resourceType, method]), from);
  const getStatus = method => (from, root = true) =>
    R.path(R.concat(root ? ['resources'] : [], [resourceType, method, 'status']), from);

  const getters = {
    getState,
    getMethod,
    getStatus,
  };

  // eslint-disable-next-line
  getters.loaded = (async () => {
    try {
      const { getters: resourceTypeGetters } = await import(/* webpackChunkName: 'rc-getters', webpackMode: 'lazy-once' */`./${resourceType}`);
      R.forEachObjIndexed((v, k) => { getters[k] = v; }, resourceTypeGetters);
      return true;
    } catch (error) {
      // console.warn(
      //   `%cUnable to import getters for resource ${resourceType}`,
      //   'font-size: 12px; color: lightcoral',
      //   error,
      // );
      return false;
    }
  })();

  /*
  **************************************************
    Reducer
  **************************************************
  */
  const initState = {
    // methods
    // [method]: {
    //   status: { loading, success, error },
    //   ...data,
    // }
  };

  const reducer = (state = initState, action) => {
    switch (action.type) {
      case actionTypes.AJAX: {
        const { cargo: { method } } = action.payload;
        return update(state, { [method]: { [state[method] ? '$merge' : '$set']: { status: { loading: true, success: null, error: null } } } });
      }
      case actionTypes.AJAX_SUCCESS: {
        const { cargo: { method }, data } = action.payload;
        return update(state, { [method]: { [state[method] ? '$merge' : '$set']: { status: { loading: false, success: true, error: '' }, ...data[method] } } });
      }
      case actionTypes.AJAX_FAILURE: {
        const { cargo: { method }, error } = action.payload;
        return update(state, { [method]: { [state[method] ? '$merge' : '$set']: { status: { loading: false, success: false, error } } } });
      }
      case actionTypes.RESET: {
        const { cargo: { method } } = action.payload;
        return update(state, { [method]: { $set: { status: { loading: null, success: null, error: null } } } });
      }
      case actionTypes.CLEAR_CACHE: {
        const { options: { timeout = CACHE_TIMEOUT } = {} } = action.payload;
        fetch = memoize(DM[resourceType].ajax, { timeout });
        return state;
      }
      default: {
        return state;
      }
    }
  };

  return {
    actionTypes,
    actionCreators,
    epics,
    getters,
    reducer,
  };
};

const generateResourcesDucks = R.pipe(
  R.values,
  R.juxt([R.identity, R.map(createResourceDuck)]),
  R.apply(R.zipObj),
);

export default generateResourcesDucks;
