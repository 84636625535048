export default {
  countries: {
    list: [
      {
        id: 0, value: 'sg', label: 'Singapore', countryCode: '+65',
      },
      {
        id: 1, value: 'vn', label: 'Vietnam', countryCode: '+84' 
      },
      {
        id: 2, value: 'my', label: 'Malaysia', countryCode: '+12' 
      },
      {
        id: 3, value: 'th', label: 'Thailand', countryCode: '+34' 
      },
    ],
  },
  cities: {
    // countryId
    0: {
      list: [
        { id: 0, value: 'rf', label: 'Raffles City' },
      ],
    },
    1: {
      list: [
        { id: 1, value: 'hn', label: 'Ha Noi' },
        { id: 2, value: 'dn', label: 'Da Nang' },
        { id: 3, value: 'hcm', label: 'Ho Chi Minh' },
      ],
    },
  },
  idTypes: {
    // countryId
    0: {
      list: [
        { id: 0, value: 'sc', label: 'Singapore Citizen' },
        { id: 1, value: 'spr', label: 'Singapore Permanent Resident' },
        { id: 3, value: 'ltvp', label: 'Long Term Visit Pass Holder' },
        { id: 4, value: 'fr', label: 'Foreigner' },
      ],
    },
    1: {
      list: [
        { id: 0, value: 'vc', label: 'Vietnam Citizen' },
        { id: 1, value: 'vpr', label: 'Vietnam Permanent Resident' },
      ],
    },
  },
};
