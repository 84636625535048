import { httpGet } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import * as R from 'ramda';

const retrieveMany = async () => R.pipe(
  () => httpGet('common/currencies'),
  response => response.then(adapter.currencies.retrieveMany.be2fe),
)();

const DM = {
  retrieveMany,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
