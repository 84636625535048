/* eslint-disable */
import api from 'apiModule/rest';
import adapter from 'dataModule/adapter';

// fetch patient today's tasks
const fetchProviderList = body => api.provider.fetchProviderList('account/providerList', body);
const fetchRegister = body => api.provider.fetchRegister('account/register', adapter.provider.provider.fe2be(body))


export default {
  fetchProviderList,
  fetchRegister
};
