import api from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import * as R from 'ramda';

const getEssentials = R.pipe(() => api.config.getEssentials('configs/essentials'), response => response.then(adapter.config.essentials.be2fe));

const DM = {
  getEssentials,
};

export default DM;
