import * as R from 'ramda';
import { httpGet, httpPost, httpPut, httpDelete } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getIdsObject, getQueriesString } from 'utilsModule';

const getProviderIndividualPatientReportsList = async ({ params: { ids, queries } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ patientId }) => `reports/provider/patient/${patientId}`,
  )(ids);

  return R.pipe(
    getQueriesString,
    queriesString => httpGet(`${url}${queriesString}`),
    response => response.then(adapter.reporting.getProviderIndividualPatientReportsList.be2fe),
  )(queries);
};

const getProviderOverallPatientReportsList = async ({ params: { queries } }) => {
  return R.pipe(
    getQueriesString,
    queriesString => httpGet(`reports/provider/overall/patient${queriesString}`),
    response => response.then(adapter.reporting.getProviderIndividualPatientReportsList.be2fe),
  )(queries);
};


const reportArchive = async ({ params: { ids }, content }) =>
  R.pipe(
    adapter.reporting.reportArchive.fe2be,
    R.pipe(
      getIdsObject,
      ({ reportId }) => httpPut(`reports/${reportId}/archive`),
    )(ids),
    response => response.then(adapter.reporting.reportArchive.fe2be),
  )(content);

const reportUnarchive = async ({ params: { ids }, content }) =>
  R.pipe(
    adapter.reporting.reportUnarchive.fe2be,
    R.pipe(
      getIdsObject,
      ({ reportId }) => httpPut(`reports/${reportId}/unarchive`),
    )(ids),
    response => response.then(adapter.reporting.reportUnarchive.fe2be),
  )(content);

const DM = {
  getProviderIndividualPatientReportsList,
  getProviderOverallPatientReportsList,
  reportArchive,
  reportUnarchive,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
