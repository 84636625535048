import * as R from 'ramda';
import { httpPost } from 'apiModule/rest';
import adapter from 'dataModule/adapter';


const getAggregatedTaskWithObservationCompiled = async ({ content }) =>
  R.pipe(
    adapter.feedbacks.saveFeedbackQuestion.fe2be,
    httpPost('tasks/observationCompiled'),
    response => response.then(adapter.taskObservation.getAggregatedTaskWithObservationCompiled.be2fe),
  )(content);


const DM = {
  getAggregatedTaskWithObservationCompiled
};

const ajax = async ({ method, input = {} }) => ({
  [method]: await DM[method](input),
});

export default { ...DM, ajax };
