import * as R from 'ramda';
import { httpGet } from 'apiModule/rest';
import adapter from 'dataModule/adapter';

const retrieveWebVersion = async () => R.pipe(
  () => import(/* webpackChunkName: 'env' */'publicModule/env.js'),
  response => response.then(adapter.versions.retrieveWebVersion.be2fe),
)();

const retrieveApiVersion = async () => R.pipe(
  () => httpGet('check-api-version'),
  response => response.then(adapter.versions.retrieveApiVersion.be2fe),
)();

const DM = {
  retrieveWebVersion,
  retrieveApiVersion,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
