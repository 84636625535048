import * as R from 'ramda';
import { httpGet, httpPost, httpPut, httpDelete } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getIdsObject, getQueriesString } from 'utilsModule';

const createAP = async ({ content }) =>
  R.pipe(adapter.programs.createAP.fe2be, httpPost('adminPrograms'), (response) =>
    response.then(adapter.programs.createAP.be2fe),
  )(content);

const retrieveAPs = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`adminPrograms${queriesString}`),
    (response) => response.then(adapter.programs.retrieveAPs.be2fe),
  )(queries);

const retrieveAP = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ apId }) => httpGet(`adminPrograms/${apId}`),
    // ({ apId }) => Promise.resolve({ value: findBy('id', parseInt(apId), mock.programs.adminPrograms) }),
    (response) => response.then(adapter.programs.retrieveAP.be2fe),
  )(ids);

const updateAP = async ({ params: { ids }, content }) =>
  R.pipe(
    R.juxt([R.pipe(R.prop('ids'), getIdsObject), R.pipe(R.prop('content'), adapter.programs.updateAP.fe2be)]),
    ([{ apId }, beContent]) => httpPut(`adminPrograms/${apId}`)(beContent),
    // () => new Promise(resolve => setTimeout(resolve, 10000)),
    (response) => response.then(adapter.programs.updateAP.be2fe),
  )({ ids, content });

const enrollPP = async ({ params: { ids }, content }) =>
  R.pipe(
    adapter.programs.enrollPP.fe2be,
    R.pipe(getIdsObject, ({ patientId }) => httpPost(`patients/${patientId}/enrolledPrograms`))(ids),
    (response) => response.then(adapter.programs.enrollPP.be2fe),
  )(content);

const createOTT = async ({ content }) =>
  R.pipe(adapter.programs.createOTT.fe2be, httpPost('tasks/createOneTimeTask'), (response) =>
    response.then(adapter.programs.createOTT.be2fe),
  )(content);

const updateOTT = async ({ params: { ids }, content }) =>
  R.pipe(
    adapter.programs.updateOTT.fe2be,
    R.pipe(getIdsObject, ({ taskId }) => httpPut(`tasks/editOneTimeTask/${taskId}`))(ids),
    (response) => response.then(adapter.programs.updateOTT.be2fe),
  )(content);

const retrievePPs = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ patientId }) => httpGet(`patients/${patientId}/enrolledPrograms`),
    (response) => response.then(adapter.programs.retrievePPs.be2fe),
  )(ids);

const retrievePP = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ patientId, ppId }) => httpGet(`patients/${patientId}/enrolledPrograms/${ppId}`),
    (response) => response.then(adapter.programs.retrievePP.be2fe),
  )(ids);

const retrieveListWithBasedPrograms = async ({ params: { ids } }) =>
    R.pipe(
      getIdsObject,
      ({ patientId, ppId }) => httpGet(`patients/${patientId}/listWithBasedPrograms/${ppId}`),
      (response) => response.then(adapter.programs.retrieveListWithBasedPrograms.be2fe),
    )(ids);

const retrieveOTTPP = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ patientId, ppId }) => httpGet(`patients/${patientId}/enrolledProgramsWithOneTimeTasks/${ppId}`),
    (response) => response.then(adapter.programs.retrieveOTTPP.be2fe),
  )(ids);

const deleteOTT = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ taskId }) => httpDelete(`tasks/deleteOneTimeTask/${taskId}`),
    (response) => response.then(adapter.programs.deleteOTT.be2fe),
  )(ids);

const updatePP = async ({ params: { ids }, content }) =>
  R.pipe(
    adapter.programs.updatePP.fe2be,
    R.pipe(getIdsObject, ({ patientId, ppId }) => httpPut(`patients/${patientId}/enrolledPrograms/${ppId}`))(ids),
    (response) => response.then(adapter.programs.updatePP.be2fe),
  )(content);

const dischargePP = async ({ params: { ids }, content }) =>
  R.pipe(
    getIdsObject,
    ({ patientId, ppId }) => httpPost(`patients/${patientId}/enrolledPrograms/${ppId}/discharge`, { ...content }),
    (response) => response.then(adapter.programs.dischargePP.be2fe),
  )(ids);

const fetchAvailableProgramList = async ({ params: { patientId, queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`patients/${patientId}/availablePrograms${queriesString}`),
    (response) => response.then(adapter.program.availableProgramList.be2fe),
  )(queries);

const fetchExtensionSuspension = async ({ params: { queries, patientId } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`patients/${patientId}/extensionSuspension${queriesString}`),
    (response) => response.then(adapter.programs.fetchExtensionSuspension.be2fe),
  )(queries);

const retrieveCurrentOrgApNames = async () =>
  R.pipe(
    () => httpGet('adminPrograms/getCurrentOrgApNames'),
    (response) => response.then(adapter.programs.retrieveCurrentOrgApNames.be2fe),
  )();

const retrieveEPCareteamMovementHistory = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`enrolledProgramCareteamHistory${queriesString}`),
    (response) => response.then(adapter.programs.retrieveEPCareteamMovementHistory.be2fe),
  )(queries);

const programTimeSpentReport = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`patients/enrolledProgram/time-spent-report${queriesString}`),
    (response) => response.then(adapter.programs.programTimeSpentReport.be2fe),
  )(queries);

const retrieveListPDFPatientVitalReport = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`patients/enrolledProgram/patient-vital-report${queriesString}`),
    (response) => response.then(adapter.programs.retrieveListPDFPatientVitalReport.be2fe),
  )(queries);

const retrieveEnrolledProgrammesByStatusAndPatient = async ({ params: { ids, queries } }) => {
  const url = R.pipe(getIdsObject, ({ patientId }) => `patients/${patientId}/enrolledProgramsByStatus`)(ids);

  return R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`${url}${queriesString}`),
    (response) => response.then(adapter.programs.retrieveEnrolledProgrammesByStatusAndPatient.be2fe),
  )(queries);
};

const retrieveAllPPs = async ({ content }) =>
  R.pipe(adapter.programs.retrieveAllPPs.fe2be, httpPost('patients/enrolledPrograms'), (response) =>
    response.then(adapter.programs.retrieveAllPPs.be2fe),
  )(content);

const releaseKiosk = async ({ content }) =>
  R.pipe(adapter.programs.releaseKiosk.fe2be, httpPost('kiosk/deactivate'), (response) =>
    response.then(adapter.programs.releaseKiosk.be2fe),
  )(content);

const updateKioskStatus = async ({ content }) =>
  R.pipe(adapter.programs.updateKioskStatus.fe2be, httpPut('kiosk/updateStatus'), (response) =>
    response.then(adapter.programs.updateKioskStatus.be2fe),
  )(content);

const getAdherencePdfDetail = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`adherence-report/detail-pdf${queriesString}`),
    (response) => response.then(adapter.programs.getAdherencePdfDetail.be2fe),
  )(queries);

const DM = {
  enrollPP,
  createAP,
  retrieveAPs,
  retrieveAP,
  updateAP,
  retrievePPs,
  retrievePP,
  retrieveListWithBasedPrograms,
  updatePP,
  dischargePP,
  retrieveCurrentOrgApNames,
  retrieveEPCareteamMovementHistory,
  programTimeSpentReport,
  retrieveListPDFPatientVitalReport,
  retrieveOTTPP,
  createOTT,
  deleteOTT,
  updateOTT,
  retrieveEnrolledProgrammesByStatusAndPatient,
  retrieveAllPPs,
  releaseKiosk,
  updateKioskStatus,
  getAdherencePdfDetail,
  fetchAvailableProgramList,
  fetchExtensionSuspension
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
