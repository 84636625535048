import * as R from 'ramda';
import resourceTypes from './types';

/*
**************************************************
  State Getters
**************************************************
*/
const RESOURCE_TYPE = resourceTypes.USERS;

/* Login */
const get2FAToken = ({ root, defaultValue = '' } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'request2FA', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'request2FA', 'token'])),
  );

const get2FAOtpVia = ({ root, defaultValue = null } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'request2FA', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'request2FA', 'otpVia'])),
  );

const getForgotPassword2FAOtpVia = ({ root, defaultValue = null } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'forgetPassword', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'forgetPassword', 'otpVia'])),
  );

const get2FASessionId = ({ root, defaultValue = null } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'verify2FA', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'verify2FA', 'sessionId'])),
  );

const get2FAProfiles = ({ root, defaultValue = [] } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'verify2FA', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'verify2FA', 'profiles'])),
  );

/* Onboard */
const getInvitationToken = ({ root, defaultValue = '' } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'requestInvitationToken', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'requestInvitationToken', 'token'])),
  );

const getOnboardMobile = ({ root, defaultValue = {} } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'requestInvitationToken', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'requestInvitationToken', 'phone'])),
  );

const getNextAction = ({ root, defaultValue = '' } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'verifyInvitationCode', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'verifyInvitationCode', 'nextAction'])),
  );

const getOtpToken = (method) => ({ root, defaultValue = '' } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, method, 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, method, 'token'])),
  );

const getOnboardSessionId = ({ root, defaultValue = '' } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'verify2FAForOnboard', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'verify2FAForOnboard', 'sessionId'])),
  );

const getDemographic = ({ root, defaultValue = '' } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'verify2FAForOnboard', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'verify2FAForOnboard', 'demographics'])),
  );

const getAppStoreUri = ({ root, defaultValue = '' } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(
          R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'requestInvitationCodeForMobile', 'status', 'success']),
        ),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'requestInvitationCodeForMobile', 'appStoreUri'])),
  );

const getPlayStoreUri = ({ root, defaultValue = '' } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(
          R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'requestInvitationCodeForMobile', 'status', 'success']),
        ),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'requestInvitationCodeForMobile', 'playStoreUri'])),
  );

const getLoggedInRole = ({ root, defaultValue = null } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'profile', 'type'])),
  );

const getLoggedInOrganization = ({ root, defaultValue = {} } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'organization'])),
  );

const getLoggedInGroupOrganization = ({ root, defaultValue = {} } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'groupOrganization'])),
  );

const getDefaultWarehouse = (from, root = true) =>
  R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'defaultWarehouse']), from);

const getCountry = (from, root = true) =>
  R.path(
    R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'profile', 'address', 'country']),
    from,
  );

const getProfilePhoto = (from, root = true) =>
  R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'profile', 'profile_photo']), from);

const getMobileCountryCode = ({ root, defaultValue = '+' } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(
      R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'demographics', 'mobile', 'countryCode']),
    ),
  );
// TODO: Change other getters's signature too
const getProviders = ({ root, defaultValue = { list: [], options: {} } } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveProviders', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.pipe(
      R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveProviders'])),
      R.pick(['list', 'options']),
    ),
  );

const getProvider = (method) => ({ root, defaultValue } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, method, 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, method, 'provider'])),
  );

const getPatients = ({ root, defaultValue = { list: [], options: {} } } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrievePatients', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.pipe(
      R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrievePatients'])),
      R.pick(['list', 'options']),
    ),
  );

const getPatient = (method) => ({ root, defaultValue } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, method, 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, method, 'patient'])),
  );

const getPatientsCovid = ({ root, defaultValue = { list: [], options: {} } } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrievePatientsCovid', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrievePatientsCovid'])),
  );

const getNotificationSettings = (method) => ({ root, defaultValue = null } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, method, 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, method, 'getNotificationSettings'])),
  );
const changeNotificationSettings = (method) => ({ root, defaultValue } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, method, 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, method, 'changeNotificationSettings'])),
  );

const getUiPermission = ({ root, defaultValue = [] } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'uiPermissions'])),
  );

const getPermissionSettings = ({ root, defaultValue = {} } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'permissionSetting'])),
  );

const getDefaultAcl = ({ root, defaultValue = {} } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveDefaultAcl', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveDefaultAcl', 'permissionSetting'])),
  );

const getNotifications = ({ root, defaultValue = {} } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveNotifications', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveNotifications'])),
  );

const getReportingEnabled = ({ root, defaultValue = false } = {}) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.pipe(
        R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'status', 'success'])),
        R.not,
      ),
    ]),
    R.always(defaultValue),
    R.path(R.concat(root ? ['resources'] : [], [RESOURCE_TYPE, 'retrieveCurrent', 'reportingEnabled'])),
  );

const getters = {
  get2FAToken,
  get2FAOtpVia,
  getForgotPassword2FAOtpVia,
  get2FASessionId,
  get2FAProfiles,
  getInvitationToken,
  getOnboardMobile,
  getNextAction,
  getOtpToken,
  getOnboardSessionId,
  getDemographic,
  getAppStoreUri,
  getPlayStoreUri,
  getLoggedInRole,
  getLoggedInOrganization,
  getLoggedInGroupOrganization,
  getDefaultWarehouse,
  getCountry,
  getProfilePhoto,
  getMobileCountryCode,
  getProviders,
  getProvider,
  getPatients,
  getPatient,
  getPatientsCovid,
  getNotificationSettings,
  changeNotificationSettings,
  getUiPermission,
  getPermissionSettings,
  getDefaultAcl,
  getNotifications,
  getReportingEnabled,
};

export { getters };
