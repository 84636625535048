import * as R from 'ramda';
import { httpGet, httpPost, httpPut, httpPatch } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString, getIdsObject } from 'utilsModule';

const create = async ({ content }) =>
  R.pipe(adapter.orgs.create.fe2be, httpPost('organizations'), (response) => response.then(adapter.orgs.create.be2fe))(
    content,
  );

const createCovid = async ({ content }) =>
  R.pipe(adapter.orgs.createCovid.fe2be, httpPost('organizations/covid'), (response) =>
    response.then(adapter.orgs.createCovid.be2fe),
  )(content);

// For id on parameter
const update = async ({ content, params: { ids } }) => {
  const url = R.pipe(getIdsObject, ({ id }) => `organizations/${id}`)(ids);

  return R.pipe(adapter.orgs.update.fe2be, httpPut(url), (response) => response.then(adapter.orgs.update.be2fe))(
    content,
  );
};

const retrieveOne = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ organizationsId }) => httpGet(`organizations/${organizationsId}`),
    (response) => response.then(adapter.orgs.retrieveOne.be2fe),
  )(ids);

const retrieveMany = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`organizations${queriesString}`),
    (response) =>
      response
        .then(adapter.orgs.retrieveMany.be2fe)
        // BE: Need to return filtering
        .then(
          R.assocPath(['filtering', 'orgType'], R.pipe(R.find(R.propEq('name', 'type')), R.prop('value'))(queries)),
        ),
  )(queries);

const accountOrganizations = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`account/organizations${queriesString}`),
    (response) =>
      response
        .then(adapter.orgs.retrieveMany.be2fe)
        // BE: Need to return filtering
        .then(
          R.assocPath(['filtering', 'orgType'], R.pipe(R.find(R.propEq('name', 'type')), R.prop('value'))(queries)),
        ),
  )(queries);

const retrieveSortingFilter = async ({ params: { queries } }) => R.pipe(
  getQueriesString,
  queriesString => httpGet(`sortAndFilterSetting${queriesString}`),
  response => response.then(adapter.orgs.retrieveSortingFilter.be2fe),
)(queries);

const retrieveAdminContactList = async ({ params: { ids, queries } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ organizationsId }) => `organizations/${organizationsId}/contact`,
  )(ids);

  return R.pipe(
    getQueriesString,
    queriesString => httpGet(`${url}${queriesString}`),
    response => response.then(adapter.orgs.retrieveAdminContactList.be2fe),
  )(queries);
};

const assignToPrimaryContact = async ({ content, params: { ids } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ organizationsId }) => `organizations/${organizationsId}/primaryContact`,
  )(ids);

  return R.pipe(
    adapter.orgs.assignToPrimaryContact.fe2be,
    httpPatch(`${url}`),
    response => response.then(adapter.orgs.assignToPrimaryContact.be2fe),
  )(content);
};

const retrieveTypes = async () =>
  R.pipe(
    () => httpGet('organizations/type'),
    (response) => response.then(adapter.orgs.retrieveTypes.be2fe),
  )();

const retrieveParents = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`organizations/parentOrg${queriesString}`),
    (response) => response.then(adapter.orgs.retrieveParents.be2fe),
  )(queries);

const retrieveDepartmentsOf = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`organizations${queriesString}&type=DEPARTMENT`),
    (response) => response.then(adapter.orgs.retrieveDepartmentsOf.be2fe),
  )(queries);

const organizationDepartmentsOf = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`account/organizations${queriesString}?type=DEPARTMENT`),
    (response) => response.then(adapter.orgs.retrieveDepartmentsOf.be2fe),
  )(queries);

const retrieveOrgInvoices = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`organizations/organizationInvoice${queriesString}`),
    (response) => response.then(adapter.orgs.retrieveOrgInvoices.be2fe),
  )(queries);

const isOrganizationWithinPlan = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    (queriesString) => httpGet(`covid/checkOrganizationLimit/${queriesString}`),
    (response) => response.then(adapter.orgs.isOrganizationWithinPlan.be2fe),
  )(queries);

const upgradeCovidSubscriptionPlan = async ({ content }) =>
  R.pipe(adapter.orgs.upgradeCovidSubscriptionPlan.fe2be, httpPut('covid/updateCovidSubscription'), (response) =>
    response.then(adapter.orgs.upgradeCovidSubscriptionPlan.be2fe),
  )(content);

const getCovidSubscriptionPlan = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ organizationsId }) => httpGet(`covid/subscriptionPlan/${organizationsId}`),
    (response) => response.then(adapter.orgs.getCovidSubscriptionPlan.be2fe),
  )(ids);

const cancelCovidSubscriptionPlan = async ({ content, params: { ids } }) => {
  const url = R.pipe(getIdsObject, ({ organizationsId }) => `covid/subscriptionPlan/${organizationsId}`)(ids);

  return R.pipe(adapter.orgs.cancelCovidSubscriptionPlan.fe2be, httpPut(url), (response) =>
    response.then(adapter.orgs.cancelCovidSubscriptionPlan.be2fe),
  )(content);
};

const DM = {
  create,
  createCovid,
  update,
  retrieveOne,
  retrieveMany,
  retrieveTypes,
  retrieveParents,
  retrieveDepartmentsOf,
  retrieveOrgInvoices,
  isOrganizationWithinPlan,
  upgradeCovidSubscriptionPlan,
  getCovidSubscriptionPlan,
  cancelCovidSubscriptionPlan,
  accountOrganizations,
  organizationDepartmentsOf,
  retrieveSortingFilter,
  retrieveAdminContactList,
  assignToPrimaryContact
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
