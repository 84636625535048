/* Epics */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

/* Libs */
import DM from 'dataModule';

/*
**************************************************
  Action Types
**************************************************
*/

const prefix = 'config/essentials';

const actionTypes = {
  INIT: Symbol(`${prefix}/INIT`),
  INIT_SUCCESS: Symbol(`${prefix}/INIT_SUCCESS`),
  INIT_FAILURE: Symbol(`${prefix}/INIT_FAILURE`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  init: () => ({ type: actionTypes.INIT }),
  initSuccess: payload => ({ type: actionTypes.INIT_SUCCESS, payload }),
  initFailure: payload => ({ type: actionTypes.INIT_FAILURE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  // init
  action$ => action$.ofType(actionTypes.INIT)
    .mergeMap(() =>
      Observable.fromPromise(DM.config.getEssentials())
        .map(essentials => actionCreators.initSuccess({ essentials }))
        .catch(error => Observable.of(actionCreators.initFailure({ error })))),
];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  loading: true,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT: {
      return { ...state, loading: true };
    }
    case actionTypes.INIT_SUCCESS: {
      const { essentials } = action.payload;
      return { ...state, loading: false, ...essentials };
    }
    default: {
      return state;
    }
  }
};
