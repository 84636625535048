import { httpGet } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getIdsObject, getQueriesString } from 'utilsModule';
import mock from 'dataModule/mock';
import * as R from 'ramda';

const retrieveOne = null;

const retrieveMany = async () => R.pipe(
  () => httpGet('common/countries'),
  response => response.then(adapter.countries.retrieveMany.be2fe),
  // R.always(mock.countries.countries),
)();

const retrieveCities = async ({ params: { queries } }) => R.pipe(
  getQueriesString,
  queriesString => httpGet(`common/cities${queriesString}`),
  // ({ countryId }) => R.propOr({ list: [] }, countryId, mock.countries.cities),
  response => response.then(adapter.countries.retrieveCities.be2fe),
)(queries);

const retrieveIdTypes = async ({ params: { ids } }) => R.pipe(
  getIdsObject,
  // ({ countryId }) => httpGet(`countries/${countryId}/idTypes`),
  ({ countryId }) => R.propOr({ list: [] }, countryId, mock.countries.idTypes),
  // response => response.then(adapter.countries.retrieveIdTypes.be2fe),
)(ids);

const retrieveiHealthCountries = async () => R.pipe(
  () => httpGet('organizations/countries'),
  response => response.then(adapter.countries.retrieveiHealthCountries.be2fe),
)();

const DM = {
  retrieveOne,
  retrieveMany,
  retrieveCities,
  retrieveIdTypes,
  retrieveiHealthCountries,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
