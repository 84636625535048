import * as R from 'ramda';
import moment from 'moment';

const create = {
  fe2be: ({
    skuId: { value: skuId },
    code,
    basePrice,
    billingSetupEnabled,
    lateDeviceReturnFee,
    earlyTerminationFee,
    lostOrDamagedOfDevicesFee,
    durationUnit: { value: chargeDurationUnit },
    currency,
    description,
    isIncludeTax,
    effectiveFrom,
    effectiveTo,
    parentChargeItemCodeId,
    rrp,
    subscriptionPeriod,
    subscriptionPeriodType,
    chargeCodeType,
    currencyTimezone,
    isArchived,
    groupOrgs,
    isExtensionEnabled,
    isSuspensionEnabled
  }) => ({
    skuId,
    code,
    basePrice: parseFloat(basePrice),
    billingSetupEnabled,
    lateDeviceReturnFee,
    earlyTerminationFee,
    lostOrDamagedOfDevicesFee,
    chargeDurationUnit,
    rrp: parseFloat(rrp),
    currency,
    description,
    isIncludeTax,
    effectiveFrom: effectiveFrom ? moment(effectiveFrom).format('YYYY-MM-DD') : null,
    effectiveTo: effectiveTo ? moment(effectiveTo).format('YYYY-MM-DD') : null,
    parentChargeItemCodeId,
    subscriptionPeriod,
    subscriptionPeriodType,
    chargeCodeType,
    currencyTimezone,
    isArchived,
    groupOrgs,
    isExtensionEnabled,
    isSuspensionEnabled
  }),
  be2fe: R.identity,
};

const edit = {
  fe2be: ({
    skuId: { value: skuId },
    code,
    basePrice,
    billingSetupEnabled,
    lateDeviceReturnFee,
    earlyTerminationFee,
    lostOrDamagedOfDevicesFee,
    durationUnit: { value: chargeDurationUnit },
    currency,
    description,
    isIncludeTax,
    effectiveFrom,
    effectiveTo,
    parentChargeItemCodeId,
    rrp,
    subscriptionPeriod,
    subscriptionPeriodType,
    chargeCodeType,
    currencyTimezone,
    isArchived,
    groupOrgs,
    isExtensionEnabled,
    isSuspensionEnabled
  }) => ({
    skuId,
    code,
    basePrice: parseFloat(basePrice),
    billingSetupEnabled,
    lateDeviceReturnFee,
    earlyTerminationFee,
    lostOrDamagedOfDevicesFee,
    chargeDurationUnit,
    rrp: parseFloat(rrp),
    currency,
    description,
    isIncludeTax,
    effectiveFrom: effectiveFrom ? moment(effectiveFrom).format('YYYY-MM-DD') : null,
    effectiveTo: effectiveTo ? moment(effectiveTo).format('YYYY-MM-DD') : null,
    parentChargeItemCodeId: parentChargeItemCodeId ? parentChargeItemCodeId.value : null,
    subscriptionPeriod,
    subscriptionPeriodType,
    chargeCodeType,
    currencyTimezone,
    isArchived,
    groupOrgs,
    isExtensionEnabled,
    isSuspensionEnabled
  }),
  be2fe: R.identity,
};

const deleteOne = {
  fe2be: R.identity,
  be2fe: R.prop('value'),
};

const publish = {
  fe2be: ({ country: { value: countryOrgId }, reason }) => ({
    countryOrgId,
    reason,
  }),
  be2fe: R.identity,
};

const retrieveOne = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const retrieveMany = {
  fe2be: R.identity,
  be2fe: ({ value: { chargeItemCodes, pagination } }) => ({
    list: chargeItemCodes,
    options: {
      pagination: {
        totalCount: pagination.total,
      },
    },
  }),
};

const retrieveCovidTier = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const retrieveActive = {
  fe2be: R.identity,
  be2fe: ({ value: { chargeItemCodes } }) => ({
    // TODO: Support priceAfterTax too
    list: R.map(({
      id,
      description,
      priceBeforeTax,
      durationUnit,
      currency,
      subscriptionPeriod,
      subscriptionPeriodType,
      chargeCodeType,
      sku: { extraInfo = '' } = {}
    }) => ({
      id,
      value: id,
      label: description,
      price: priceBeforeTax,
      frequency: durationUnit,
      currency,
      subscriptionPeriod,
      subscriptionPeriodType,
      chargeCodeType,
      extraInfo,
    }))(chargeItemCodes),
  }),
};

const retrieveActiveIhealth = {
  fe2be: R.identity,
  be2fe: ({ value: { chargeItemCodes } }) => ({
    list: chargeItemCodes,
  }),
};

export default {
  create,
  edit,
  deleteOne,
  publish,
  retrieveOne,
  retrieveMany,
  retrieveActive,
  retrieveActiveIhealth,
  retrieveCovidTier,
};
