/* eslint-disable */
import * as R from 'ramda';
import moment from 'moment-timezone';

const adminProgram = {
  fe2be: ({ info: feInfo, vitals: feVitals, assessments: feAssessments }) => {
    const beTitle = R.path(['title'], feInfo);
    const beDescription = R.pathOr('', ['description'], feInfo);
    const beCondition = R.path(['condition', 'value'], feInfo);
    const beDuration = {
      length: R.path(['length'], feInfo),
      calendar: R.path(['calendar', 'value'], feInfo),
    };
    const selectedVitalGroupNames = R.filter(v => v !== 'selected' && feVitals.selected[v], R.keys(feVitals));
    const padThreshold = v => ({
      criticallyLow: R.propOr(null, 'criticallyLow', v),
      low: R.propOr(null, 'low', v),
      high: R.propOr(null, 'high', v),
      criticallyHigh: R.propOr(null, 'criticallyHigh', v),
    });
    const beVitals = R.map(grpName => {
      const { thresholds: feThresholds, schedule: feSchedule, device: feDevice } = feVitals[grpName];
      const beThresholds = {};
      R.map(dimName => beThresholds[dimName] = R.values(padThreshold(feThresholds[dimName])), R.keys(feThresholds));

      // TODO: Ask Ethan to implement schedule_v2 proposal
      const scheduleTypeMapping = {
        'frequency': 'FREQUENCY',
        'timeslots': 'TIMESLOT',
      };

      const scheduleDetails = feSchedule.type === 'frequency'
        ? { frequency: { calendar: feSchedule.frequency.repeat.value, times: feSchedule.frequency.times } }
        : { timeslot: R.map(({ from, to }) => ({
          start: `${String(moment(from).hour()).padStart(2, '0')}${String(moment(from).minute()).padStart(2, '0')}`,
          end: `${String(moment(to).hour()).padStart(2, '0')}${String(moment(to).minute()).padStart(2, '0')}`,
        }), feSchedule.timeslots) };

      const schedule = {
        taskType: 'NON_BG', // Fix this for now
        scheduleType: scheduleTypeMapping[feSchedule.type],
        scheduleDetails,
      };

      return {
        vitalGroupName: grpName,
        schedule,
        notifications: null, // NOTE: Harry team
        thresholds: beThresholds,
        skuId: feDevice.skuId.value,
      };
    }, selectedVitalGroupNames);
    const beDevices = R.map(R.pipe(R.prop(R.__, feVitals), R.path(['device', 'skuId', 'value']), skuId => ({ skuId, chargeItemCodeId: null })), selectedVitalGroupNames);
    
    // console.log('%cbeVitals', 'font-size: 12px; color: #00b3b3', beVitals);
    // console.log('%cbeDevices', 'font-size: 12px; color: #00b3b3', beDevices);

    return {
      title: beTitle,
      description: beDescription,
      condition: beCondition,
      duration: beDuration,
      vitals: beVitals,
      devices: beDevices,
    };
  },
  be2fe: null,
};

const adminProgramList = {
  fe2be: pagination => ({
    ...pagination,
    // TODO: Ask Ethan to start paging index at 0
    page: R.inc(pagination.page),
  }),
  be2fe: ({ value }) => {    
    const list = R.path(['adminPrograms'], value);
    const pagination = R.path(['pagination'], value);    
    
    // TODO: Ask Ethan to send number instead of string
    return {
      list,
      pagination: {
        currentPage: R.dec(parseFloat(pagination.page)),
        pageSize: parseFloat(pagination.pageSize),
        totalCount: parseFloat(pagination.total),
      }
    }
  },
};

const availableProgramList = {
  fe2be: null,
  be2fe: ({ value: { adminPrograms } }) => ({
    list: R.map(({
      category: condition,
      pricing,
      createdBy: {
        demographics: {
          name: createdBy,
          created_profile_photo
        },
      },
      createdAt,
      updatedBy: {
        demographics: {
          name: updatedBy,
          updated_profile_photo
        },
      },
      updatedAt,
      ...rest
    }) => ({
      condition,
      created: {
        name: createdBy || {},
        at: createdAt,
        avatar: created_profile_photo || {}
      },
      modified: {
        name: updatedBy || {},
        at: updatedAt,
        avatar: updated_profile_photo || {}
      },
      // TODO: Ask BE to return numeric instead of string for number
      pricing: R.evolve({
        totals: R.map(({
          priceBeforeTax, ...rest
        }) => ({
          priceBeforeTax: parseFloat(priceBeforeTax), ...rest
        })),
      })(pricing),
      ...rest,
    }))(adminPrograms),
  }),
};

const enrolledProgramList = {
  fe2be: null,
  be2fe: ({ value }) => {
    return {
      list: R.path(['enrolledPrograms'], value),
    }
  },
};

const enrolledProgram = {
  fe2be: ({ adminProgramId, info: feInfo, vitals: feVitals, assessments: feAssessments, apToEnroll }) => {
    const beTitle = R.path(['title'], feInfo);
    const beDescription = R.pathOr('', ['description'], feInfo);
    const beCondition = R.path(['condition'], feInfo);
    const beDuration = {
      length: R.path(['duration', 'length'], feInfo),
      calendar: R.path(['duration', 'calendar'], feInfo),
    };
    const bePeriod = {
      start: feInfo.startDate,
      end: feInfo.endDate,
    }
    const padThreshold = v => ({
      criticallyLow: R.propOr(null, 'criticallyLow', v),
      low: R.propOr(null, 'low', v),
      high: R.propOr(null, 'high', v),
      criticallyHigh: R.propOr(null, 'criticallyHigh', v),
    });
    const beVitals = R.map(grpName => {
      const { thresholds: feThresholds, schedule: feSchedule, device: feDevice } = feVitals[grpName];
      const beThresholds = {};
      R.map(dimName => beThresholds[dimName] = R.values(padThreshold(feThresholds[dimName])), R.keys(feThresholds));

      // TODO: Ask Ethan to implement schedule_v2 proposal
      const scheduleTypeMapping = {
        'frequency': 'FREQUENCY',
        'timeslots': 'TIMESLOT',
      };

      const scheduleDetails = feSchedule.type === 'frequency'
        ? { frequency: { calendar: feSchedule.frequency.repeat.value, times: feSchedule.frequency.times } }
        : { timeslot: R.map(({ from, to }) => ({
          start: `${String(moment(from).hour()).padStart(2, '0')}${String(moment(from).minute()).padStart(2, '0')}`,
          end: `${String(moment(to).hour()).padStart(2, '0')}${String(moment(to).minute()).padStart(2, '0')}`,
        }), feSchedule.timeslots) };

      const schedule = {
        taskType: 'NON_BG', // Fix this for now
        scheduleType: scheduleTypeMapping[feSchedule.type],
        scheduleDetails,
      };

      return {
        vitalGroupName: grpName,
        schedule,
        notifications: null, // NOTE: Harry team
        thresholds: beThresholds,
        skuId: R.find(R.propEq('vitalGroupName', grpName), apToEnroll.vitals).skuId,
      };
    }, R.keys(feVitals));
    const beDevices = R.map(({ vitalGroupName, skuId }) => ({ skuId, chargeItemCodeId: null, deviceId: feVitals[vitalGroupName].device.serializedDevices.value }), apToEnroll.vitals);
    
    // console.log('%cbeVitals', 'font-size: 12px; color: #00b3b3', beVitals);
    // console.log('%cbeDevices', 'font-size: 12px; color: #00b3b3', beDevices);

    return {
      adminProgramId,
      title: beTitle,
      description: beDescription,
      condition: beCondition,
      duration: beDuration,
      period: bePeriod,
      vitals: beVitals,
      devices: beDevices,
    };
  },
  be2fe: null,
};

export default {
  adminProgram,
  adminProgramList,
  availableProgramList,
  enrolledProgramList,
  enrolledProgram,
};