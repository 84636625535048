import * as R from 'ramda';

const patientList = {
  fe2be: pagination => ({
    ...pagination,
    // TODO: Ask Ethan to start paging index at 0
    page: R.inc(pagination.page),
  }),
  be2fe: ({ value }) => {
    const list = R.path(['patients'], value);
    const pagination = R.path(['pagination'], value);

    // TODO: Ask Ethan to send number instead of string
    return {
      list,
      pagination: {
        currentPage: R.dec(parseFloat(pagination.page)),
        pageSize: parseFloat(pagination.pageSize),
        totalCount: parseFloat(pagination.total),
      },
    };
  },
};

export default {
  patientList,
};
