import * as R from 'ramda';

const retrieveMany = {
  fe2be: R.identity,
  be2fe: ({ value: { skus } }) => ({
    list: skus,
  }),
};

export default {
  retrieveMany,
};
