import omit from 'lodash/omit';

const skuLowStockThreshold = {
  fe2be: body => ({
    skuLowStockThresholds: Object.keys(omit(body, ['orgId'])).map((key) => {
      if (key.includes('con')) return null;

      const skuId = key.substring(4);
      if (body[`con-${skuId}`]) {
        return {
          skuId: key.substring(4),
          threshold: body[key],
          expiryWithinMonths: body[`con-${skuId}`],
        };
      }
      return {
        skuId: key.substring(4),
        threshold: body[key],
      };
    }).filter(item => item),
  }),
  be2fe: null,
};

export default {
  skuLowStockThreshold,
};
