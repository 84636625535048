// Imports
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'react-router-redux';

// Reducers
import rootReducer from './rootReducer';

// Epics
import rootEpic from './rootEpic';

// History
import history from './history';

const reactRouterMiddleware = routerMiddleware(history);
const epicMiddleware = createEpicMiddleware(rootEpic);

const composeEnhancers =
  typeof window === 'object' &&
  /* eslint-disable no-underscore-dangle */
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      /* eslint-enable */
      serialize: {
        replacer: (key, value) => (typeof value === 'symbol' ? String(value) : value),
      },
      maxAge: 100,
      features: {
        pause: true, // start/pause recording of dispatched actions
        lock: true, // lock/unlock dispatching actions and side effects
        persist: true, // persist states on page reloading
        export: true, // export history of actions in a file. Default is 'custom', true --> computed state
        import: true, // import history of actions from a file. Default is 'custom', true --> computed state
        jump: true, // jump back and forth (time travelling)
        skip: true, // skip (cancel) actions
        reorder: true, // drag and drop actions in the history list
        dispatch: true, // dispatch custom actions or action creators
        test: true, // generate tests for the selected actions
      },
    })
    : compose;

const middlewares = applyMiddleware(
  reactRouterMiddleware,
  thunk.withExtraArgument({}),
  epicMiddleware,
);

const store = createStore(rootReducer, composeEnhancers(middlewares));

/* NOTE:
  Changes to all reducers are caught here, and won't be propagated to module.hot in index.js.
  Thus, won't trigger rerendering of component tree.
*/
if (window.MILO_CONFIG && window.MILO_CONFIG.ENABLE_HOT_RELOADING_JS && module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./rootReducer', () => {
    // fix eslint Unexpected require error
    import('./rootReducer').then(nextRootReducer => store.replaceReducer(nextRootReducer));
  });

  // Enable Webpack hot module replacement for epics
  module.hot.accept('./rootEpic', () => {
    // fix eslint Unexpected require error
    import('./rootReducer').then(nextRootEpic => epicMiddleware.replaceEpic(nextRootEpic));
  });
}

export default store;
