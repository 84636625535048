import * as R from 'ramda';
import { httpGet, httpPost, httpPut } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString, getIdsObject } from 'utilsModule';

const create = async ({ content }) =>
  R.pipe(
    adapter.careteams.create.fe2be,
    httpPost('careteam'),
    response => response.then(adapter.careteams.create.be2fe),
  )(content);

const update = async ({ content, params: { ids } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ id }) => `careteam/${id}`,
  )(ids);

  return R.pipe(
    adapter.careteams.update.fe2be,
    httpPut(url),
    response => response.then(adapter.careteams.update.be2fe),
  )(content);
};

const retrieveOne = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ careTeamId }) => httpGet(`careteam/${careTeamId}`),
    response => response.then(adapter.careteams.retrieveOne.be2fe),
  )(ids);

const retrieveMany = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`careteam${queriesString}`),
    response => response.then(adapter.careteams.retrieveMany.be2fe),
  )(queries);

const retrieveAvailableMembers = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`careteam/available-providers${queriesString}`),
    response => response.then(adapter.careteams.retrieveAvailableMembers.be2fe),
  )(queries);

const retrieveCareteamHistory = async ({ params: { queries } }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`careteam/movement${queriesString}`),
    response => response.then(adapter.careteams.retrieveCaretemHistory.be2fe),
  )(queries);

const retrieveMemberTree = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ providerId }) => httpGet(`careteam/memberTree/${providerId}`),
    response => response.then(adapter.careteams.retrieveMemberTree.be2fe),
  )(ids);

const replaceMember = async ({ content }) =>
  R.pipe(
    adapter.careteams.replaceMember.fe2be,
    httpPost('careteam/replaceMember'),
    response => response.then(adapter.careteams.replaceMember.be2fe),
  )(content);

const retrieveCareteamKioskMapping = async ({ params: { ids } }) =>
  R.pipe(
    getIdsObject,
    ({ kioskProgramId }) => httpGet(`careteam/getMap/${kioskProgramId}`),
    response => response.then(adapter.careteams.retrieveCareteamKioskMapping.be2fe),
  )(ids);

const DM = {
  create,
  update,
  retrieveOne,
  retrieveMany,
  retrieveAvailableMembers,
  retrieveCareteamHistory,
  retrieveMemberTree,
  replaceMember,
  retrieveCareteamKioskMapping,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
