/* eslint-disable */
import api from 'apiModule/rest';

// query serialized item (devices) in a warehouse
const deviceWarehouseList = body => {
  const endpoint = `device/warehouses/${body.warehouseId}?skuId=${body.skuId}&page_size=${body.page_size}&page_no=${body.page_no}`;
  return api.device.deviceWarehouseList(endpoint);
};

// query all available devices
const deviceAvailableList = body => {
  let endpoint = body.type && `device/available?type=${body.type}`;
  endpoint = body.model ? `device/available?type=${body.type}&model=${body.model}` : endpoint;
  endpoint = body.keyword ? `device/available?type=${body.type}&model=${body.model}&keyword=${body.keyword}` : endpoint;
  return api.device.deviceAvailableList(endpoint);
};

export default {
    deviceWarehouseList,
    deviceAvailableList,
};
