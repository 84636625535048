import * as R from 'ramda';

const create = {
  fe2be: R.identity,
  be2fe: R.prop('value'),
};

export default {
  create,
};
