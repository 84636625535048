import { combineEpics } from 'redux-observable';
import { epics as configEpics } from 'dataModule/config/';
import { epics as programEpics } from 'appModule/program/ducks';
import { epics as patientEpics } from 'appModule/roles/patient/ducks';
import { epics as chatEpics } from 'appModule/chat/ducks';
import { epics as taskEpics } from 'appModule/tasks/ducks';
import { epics as measurementEpics } from 'appModule/measurements/ducks';
import { epics as noteEpics } from 'appModule/note/ducks';
import { epics as alertEpics } from 'appModule/alerts/ducks';
import { epics as inventoryEpics } from 'appModule/inventory/ducks';
import { epics as consumablesEpics } from 'appModule/consumables/ducks';
import { epics as resourcesEpics } from 'dataModule/store/resources';
import { epics as messageEpics } from 'appModule/message/ducks';
import { epics as globalSettingEpics } from 'appModule/globalSetting/ducks';

export default combineEpics(
  ...configEpics,
  ...patientEpics,
  ...programEpics,
  ...chatEpics,
  ...taskEpics,
  ...measurementEpics,
  ...noteEpics,
  ...alertEpics,
  ...inventoryEpics,
  ...resourcesEpics,
  ...consumablesEpics,
  ...messageEpics,
  ...globalSettingEpics,
);
