import * as R from 'ramda';

const retrieveChangeRequestFormByUuid = {
  fe2be: R.identity,
  be2fe: ({ value }) => (
    { value }
  ),
};

export default {
  retrieveChangeRequestFormByUuid,
};
