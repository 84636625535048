import * as R from 'ramda';
import { httpPost } from 'apiModule/rest';
import adapter from 'dataModule/adapter';

const create = async ({ content }) => R.pipe(
  adapter.audittrail.create.fe2be,
  httpPost('l'),
  response => response.then(adapter.audittrail.create.be2fe),
)(content);

const DM = {
  create,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
