import * as R from 'ramda';

const retrieveIDTypes = {
  fe2be: R.identity,
  be2fe: ({ value: list }) => ({ list }),
};

const retrieveRoles = {
  fe2be: R.identity,
  be2fe: ({ value: list }) => ({ list }),
};

const retrieveSpecialties = {
  fe2be: R.identity,
  be2fe: ({ value: list }) => ({ list }),
};

const retrieveRaces = {
  fe2be: R.identity,
  be2fe: ({ value: list }) => ({ list }),
};

const retrieveLanguages = {
  fe2be: R.identity,
  be2fe: ({ value: list }) => ({ list }),
};

const retrieveNationalities = {
  fe2be: R.identity,
  be2fe: ({ value: list }) => ({ list }),
};

const retrieveCountries = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({
    list: R.map(R.pipe(
      ({ iso, ...rest }) => ({
        iso: ({
          LAO: 'LA',
          VNM: 'VN',
          BRU: 'BN',
          MMR: 'MM',
        })[iso] || iso,
        ...rest,
      }),
      ({ iso, ...rest }) => ({
        ...rest,
        iso,
        flag: `https://flagcdn.com/24x18/${iso.toLowerCase()}.png`,
      }),
    ))(value),
  }),
};

const retrieveLoginCountryCodes = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({
    list: R.map(R.pipe(
      ({ iso, ...rest }) => ({
        iso: ({
          LAO: 'LA',
          VNM: 'VN',
          BRU: 'BN',
          MMR: 'MM',
        })[iso] || iso,
        ...rest,
      }),
      ({ iso, ...rest }) => ({
        ...rest,
        iso,
        flag: `https://flagcdn.com/24x18/${iso.toLowerCase()}.png`,
      }),
    ))(value),
  }),
};

const retrieveStates = {
  fe2be: R.identity,
  be2fe: ({ value: list }) => ({ list }),
};

const retrieveCitiesByState = {
  fe2be: R.identity,
  be2fe: ({ value: list }) => ({ list }),
};

const retrieveCities = {
  fe2be: R.identity,
  be2fe: ({ value: list }) => ({ list }),
};

const retrieveHealthConditions = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

export default {
  retrieveIDTypes,
  retrieveRoles,
  retrieveSpecialties,
  retrieveRaces,
  retrieveLanguages,
  retrieveNationalities,
  retrieveCountries,
  retrieveStates,
  retrieveCitiesByState,
  retrieveCities,
  retrieveLoginCountryCodes,
  retrieveHealthConditions,
};
