import * as R from 'ramda';
import { httpGet, httpPut } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString, getIdsObject } from 'utilsModule';

const retrieveNextContractNo = async ({ params: { ids, queries } }) => {
  const url = R.pipe(
    getIdsObject,
    ({ patientId }) => `patients/${patientId}/contractNo`,
  )(ids);

  return R.pipe(
    getQueriesString,
    queriesString => httpGet(`${url}${queriesString}`),
    response => response.then(adapter.contractNo.retrieveNextContractNo.be2fe),
  )(queries);
};

const DM = {
  retrieveNextContractNo,
};

const ajax = async ({ method, input }) => ({
  [method]: await DM[method](input),
});

export default { ...DM, ajax };
