/* eslint-disable */
import * as R from 'ramda';
import api from 'apiModule/rest';
import adapter from 'dataModule/adapter';

const provider = {
  fetchPatientList: async pagination =>
    R.pipe(
      adapter.patient.patientList.fe2be,
      ({ page, pageSize }) =>
        api.patient.provider.fetchPatientList(`patients?page=${page}&pageSize=${pageSize}`),
      response => response.then(adapter.patient.patientList.be2fe),
    )(pagination),
  fetchRegister: async body => api.patient.provider.fetchRegister('account/register', body),
  fetchPatientProfile: async ({ patientId }) =>
    api.patient.provider.fetchPatientProfile(`account/getPatientInfo?patientId=${patientId}`)
      .then(adapter.users.retrievePatient.be2fe),
};

const patientMinList = body => {
  const endpoint = body.search
    ? `account/patientMinList?page_size=${body.page_size}&page_no=${body.page_no}&search=${
        body.search
      }`
    : `account/patientMinList?page_size=${body.page_size}&page_no=${body.page_no}`;

  return api.patient.patientMinList(endpoint, body);
};

export default {
  provider,
  patientMinList,
};
