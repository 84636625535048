import * as R from 'ramda';

const retrievePatient = {
  fe2be: R.identity,
  be2fe: ({ value: { patient } }) => (
    { patient }
  ),
};

const retrievePatientExtensionSuspension = {
  fe2be: R.identity,
  be2fe: ({
    value: {
      extensionSuspension,
      pagination: { page_no: page, page_size: pageSize, total },
    },
  }) => ({
    list: extensionSuspension,
    pagination: {
      currentPage: page - 1,
      pageSize,
      totalCount: total,
    },
  }),
};

const retrievePatientOneExtensionSuspension = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const deleteExtensionSuspension = {
  fe2be: R.identity,
  be2fe: R.prop('value'),
};

const retrieveActiveEnrolledPrograms = {
  fe2be: R.identity,
  be2fe: ({
    value: {
      enrolledPrograms,
    },
  }) => ({
    list: enrolledPrograms,
  }),
};

const createPatientExtensionSuspension = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const updatePatientExtensionSuspension = {
  fe2be: R.identity,
  be2fe: R.identity,
};

export default {
  retrievePatient,
  retrievePatientExtensionSuspension,
  retrieveActiveEnrolledPrograms,
  createPatientExtensionSuspension,
  updatePatientExtensionSuspension,
  retrievePatientOneExtensionSuspension,
  deleteExtensionSuspension
};
