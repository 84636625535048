import * as R from 'ramda';

const retrieveOne = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ policy: value }),
};

export default {
  retrieveOne,
};
