import api from 'apiModule/rest';

// fetch notes
const fetchList = body => api.note.fetchList('note/list', body);

// create notes
const create = body => api.note.create('note/create', body);

// mark note as error
const markAsError = body => api.note.markAsError('note/markNoteAsError', body);

export default {
  fetchList,
  create,
  markAsError,
};
