/* eslint-disable */
import * as R from 'ramda';

const essentials = {
  fe2be: null,
  be2fe: R.prop('value'),
};

export default {
  essentials,
};