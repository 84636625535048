import * as R from 'ramda';

const retrieveWebVersion = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const retrieveApiVersion = {
  fe2be: R.identity,
  be2fe: ({ value: { version = '' } = {} }) => ({ version }),
};

export default {
  retrieveWebVersion,
  retrieveApiVersion,
};
