const resourceTypes = {
    USERS: 'users',
    CARETEAMS: 'careteams',
    ESSENTIALS: 'essentials',
    CONFIGS: 'configs',
    POLICIES: 'policies',
    ORGS: 'orgs',
    PROGRAMS: 'programs',
    COUNTRIES: 'countries',
    NATIONALITIES: 'nationalities',
    GENDERS: 'genders',
    // TODO: Change this to CHARGE_ITEM_CODES: 'chargeItemCodes'
    CHARGEITEMCODES: 'chargeitemcodes',
    AUDITTRAIL: 'audittrail',
    BILLING: 'billing',
    INVENTORY: 'inventory',
    PATIENTS: 'patients',
    VERSIONS: 'versions',
    CHANGEREQUEST: 'changeRequest',
    CONTRACTNO: 'contractNo',
    CONTRACTS: 'contracts',
    SKU: 'sku',
    DEVICES: 'devices',
    CURRENCIES: 'currencies',
    FEEDBACKS: 'feedbacks',
    FEEDBACK_RESPONSE: 'feedbackResponse',
    TASK_OBSERVATION: 'taskObservation',
    REPORTING: 'reporting',
    REPORT: 'report',
    ADT: 'adt',
    ADTSURVEY: 'adtSurvey',
    ADHERENCE_REPORT: 'adherenceReport',
};

export default resourceTypes;