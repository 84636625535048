const ROLES = {
  SUPERADMIN: 'SUPERADMIN',
  ADMIN: 'ADMIN',
  DOCTOR: 'DOCTOR',
  NURSE: 'NURSE',
  CARE_MANAGER: 'CARE_MANAGER',
  PHYSIOTHERAPIST: 'PHYSIOTHERAPIST',
  DIETICIAN: 'DIETICIAN',
  PATIENT: 'PATIENT',
  OTHERS: 'OTHERS',
};

const ROLE_MAPPING = [
  { id: 'PATIENT', value: 'PATIENT', label: 'Participant' },
  { id: 'DOCTOR', value: 'DOCTOR', label: 'Doctor' },
  { id: 'NURSE', value: 'NURSE', label: 'Nurse' },
  { id: 'CARE_MANAGER', value: 'CARE_MANAGER', label: 'Care Manager' },
  { id: 'PHYSIOTHERAPIST', value: 'PHYSIOTHERAPIST', label: 'Physiotherapist' },
  { id: 'DIETICIAN', value: 'DIETICIAN', label: 'Dietician' },
  { id: 'OTHERS', value: 'OTHERS', label: 'Others' },
];

const ORG_TYPES = {
  SYSTEM: 'SYSTEM',
  COUNTRY: 'COUNTRY',
  GROUP: 'GROUP',
  CLUSTER: 'CLUSTER',
  HOSPITAL_CLINIC: 'HOSPITAL_CLINIC',
  DEPARTMENT: 'DEPARTMENT',
};

export { ROLES, ROLE_MAPPING, ORG_TYPES };
