import { httpGet } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString } from 'utilsModule';
import * as R from 'ramda';

const retrieveIDTypes = async () => R.pipe(
  () => httpGet('common/IDTypes'),
  response => response.then(adapter.configs.retrieveIDTypes.be2fe),
)();

// BE: To include SUPERADMIN & ADMIN
const retrieveRoles = async () => R.pipe(
  () => httpGet('provider/role'), // BE: Change to common/roles
  response => response.then(adapter.configs.retrieveRoles.be2fe),
)();

const retrieveSpecialties = async () => R.pipe(
  () => httpGet('common/specialities'), // BE: Typo - specialities
  response => response.then(adapter.configs.retrieveSpecialties.be2fe),
)();

const retrieveRaces = async () => R.pipe(
  () => httpGet('common/races'),
  response => response.then(adapter.configs.retrieveRaces.be2fe),
)();

const retrieveLanguages = async () => R.pipe(
  () => httpGet('common/languages'),
  response => response.then(adapter.configs.retrieveLanguages.be2fe),
)();

const retrieveNationalities = async () => R.pipe(
  () => httpGet('common/nationalities'),
  response => response.then(adapter.configs.retrieveNationalities.be2fe),
)();

const retrieveCountries = async () => R.pipe(
  () => httpGet('common/countries'),
  response => response.then(adapter.configs.retrieveCountries.be2fe),
)();

const retrieveStates = async ({ params: { queries } }) => R.pipe(
  getQueriesString,
  queriesString => httpGet(`common/states${queriesString}`),
  response => response.then(adapter.configs.retrieveStates.be2fe),
)(queries);

const retrieveCitiesByState = async ({ params: { queries } }) => R.pipe(
  getQueriesString,
  queriesString => httpGet(`common/citiesByState${queriesString}`),
  response => response.then(adapter.configs.retrieveCitiesByState.be2fe),
)(queries);

const retrieveCities = async ({ params: { queries } }) => R.pipe(
  getQueriesString,
  queriesString => httpGet(`common/cities${queriesString}`),
  response => response.then(adapter.configs.retrieveCities.be2fe),
)(queries);

const retrieveLoginCountryCodes = async ({ params: { queries } }) => R.pipe(
  getQueriesString,
  queriesString => httpGet(`common/loginCountryCodes${queriesString}`),
  response => response.then(adapter.configs.retrieveLoginCountryCodes.be2fe),
)(queries);

const retrieveHealthConditions = async ({ params: { queries } }) => R.pipe(
  getQueriesString,
  queriesString => httpGet(`common/healthConditions${queriesString}`),
  response => response.then(adapter.configs.retrieveHealthConditions.be2fe),
)(queries);

const DM = {
  retrieveIDTypes,
  retrieveRoles,
  retrieveSpecialties,
  retrieveRaces,
  retrieveLanguages,
  retrieveNationalities,
  retrieveCountries,
  retrieveStates,
  retrieveCitiesByState,
  retrieveCities,
  retrieveLoginCountryCodes,
  retrieveHealthConditions,
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
