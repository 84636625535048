/* eslint-disable */
import api from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import * as R from 'ramda';

const listSkuLowStockThreshold = body => {
  const endpoint = body.orgId ? `skuLowStockThreshold/list?orgId=${body.orgId}` : 'skuLowStockThreshold/list';
  const response = api.skuLowStockThreshold.listSkuLowStockThreshold(endpoint);
  return response;
  // return response.then(adapter.skuLowStockThreshold.skuLowStockThreshold.be2fe);
  // return R.pipe(api.skuLowStockThreshold.listSkuLowStockThreshold(endpoint), response => response.then(adapter.skuLowStockThreshold.skuLowStockThreshold.be2fe))
};

// create or edit low stock threshold of a sku of an organization
const editSkuLowStockThreshold = body => {
  const endpoint = body.orgId ? `skuLowStockThreshold?orgId=${body.orgId}` : 'skuLowStockThreshold';
  return api.skuLowStockThreshold.editSkuLowStockThreshold(endpoint, adapter.skuLowStockThreshold.skuLowStockThreshold.fe2be(body));
};

export default {
  listSkuLowStockThreshold,
  editSkuLowStockThreshold,
};
