import api from 'apiModule/rest';

// fetch patient today's alerts
const fetchList = body => api.alert.fetchList('alerts', body);
const reviewAlert = body => api.alert.reviewAlert('alerts/review', body);
const closeAlert = body => api.alert.closeAlert('alerts/close', body);

export default {
  fetchList,
  reviewAlert,
  closeAlert,
};
