import * as R from 'ramda';

const retrieveMany = {
  fe2be: R.identity,
  be2fe: ({ value: { contracts }, totalRecords }) => (
    {
      list: contracts,
      options: {
        pagination: {
          totalCount: totalRecords,
        },
      },
    }
  ),
};

export default {
  retrieveMany,
};
