/* eslint-disable */
import api from 'apiModule/rest';

// query lot item (consumables) in a warehouse by skuId
const consumableWarehouseList = body => {
  const endpoint = `consumable/warehouses/${body.warehouseId}?skuId=${body.skuId}&page_size=${body.page_size}&page_no=${body.page_no}`;
  return api.consumable.consumableWarehouseList(endpoint);
};

// query all lot item (consumables) in a warehouse
const consumableAvailableList = body => {
  const endpoint = `consumable/warehouses/available/?warehouseId=${body.warehouseId}&page_size=${body.page_size}&page_no=${body.page_no}`;
  return api.consumable.consumableAvailableList(endpoint);
};

export default {
  consumableWarehouseList,
  consumableAvailableList,
};
