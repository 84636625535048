import * as R from 'ramda';
import { httpGet } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getQueriesString, getQueriesObject } from 'utilsModule';
import { attachPaginationQueryInfo } from 'dataModule/dataManager/utils';
import * as _ from 'lodash';

const getAdherenceReports = async ({ params: { queries = [] } = {} }) =>
  R.pipe(
    getQueriesString,
    queriesString => httpGet(`adherence-report${queriesString}`),
    response => response.then(R.pipe(
      adapter.feedbacks.getFeedbacks.be2fe,
      attachPaginationQueryInfo(getQueriesObject(queries)),
    )),
  )(queries);

const DM = {
  getAdherenceReports
};

const ajax = async ({ method, input = {} }) => ({
  [method]: await DM[method](input),
});

export default { ...DM, ajax };
