import api from 'apiModule/rest';
import { getQueriesString } from 'utilsModule';

// fetch patient today's alerts
const fetchList = body => api.chat.fetchList(`chat${getQueriesString(body)}`)
const chatRoomById = epId => api.chat.chatRoomById(`chat/${epId}`);

export default {
  fetchList,
  chatRoomById,
};
